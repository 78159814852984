import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhotoStoreService {
  private _photoSet: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public readonly photoSet$: Observable<any> = this._photoSet.asObservable();

  private _photoItemTapeMark: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public readonly photoItemTapeMark$: Observable<any> = this._photoItemTapeMark.asObservable();

  private photosItemStateRepair: any[] = [];
  private _photosItemStateRepair: BehaviorSubject<any[]> = new BehaviorSubject(this.photosItemStateRepair);
  public readonly photosItemStateRepair$: Observable<any[]> = this._photosItemStateRepair.asObservable();

  private photosItemStateReplacement: any[] = [];
  private _photosItemStateReplacement: BehaviorSubject<any[]> = new BehaviorSubject(this.photosItemStateReplacement);
  public readonly photosItemStateReplacement$: Observable<any[]> = this._photosItemStateReplacement.asObservable();

  private photosItemStateSuperficialIssue: any[] = [];
  private _photosItemStateSuperficialIssue: BehaviorSubject<any[]> = new BehaviorSubject(this.photosItemStateSuperficialIssue);
  public readonly photosItemStateSuperficialIssue$: Observable<any[]> = this._photosItemStateSuperficialIssue.asObservable();

  // The string is base64 encoded photo URL
  private photosItemStateGoodQuality: string[] = [];
  private _photosItemStateGoodQuality: BehaviorSubject<string[]> = new BehaviorSubject(this.photosItemStateGoodQuality);
  public readonly photosItemStateGoodQuality$: Observable<string[]> = this._photosItemStateGoodQuality.asObservable();

  public photoFileSet?: File;
  public photoFileItemTapeMark?: File;
  public photoFilesItemStateRepair: File[] = [];
  public photoFilesItemStateReplacement: File[] = [];
  public photoFilesItemStateSuperficialIssue: File[] = [];
  public photoFilesItemStateGoodQuality: File[] = [];

  public changedItemStatePhotos: (number | string)[] = [];

  resetStore(): void {
    this._photoSet.next(undefined);
    this._photoItemTapeMark.next(undefined);

    this._photosItemStateRepair.next([]);
    this._photosItemStateReplacement.next([]);
    this._photosItemStateSuperficialIssue.next([]);
    this._photosItemStateGoodQuality.next([]);

    this.photosItemStateRepair = [];
    this.photosItemStateReplacement = [];
    this.photosItemStateSuperficialIssue = [];
    this.photosItemStateGoodQuality = [];

    this.photoFileSet = undefined;
    this.photoFileItemTapeMark = undefined;
    this.photoFilesItemStateRepair = [];
    this.photoFilesItemStateReplacement = [];
    this.photoFilesItemStateSuperficialIssue = [];
    this.photoFilesItemStateGoodQuality = [];

    this.changedItemStatePhotos = [];
  }

  /**
   * Set
   */
  storePhotoSet(photo: any): void {
    this._photoSet.next(photo);
  }

  getPhotoSet(): any {
    return this._photoSet.value;
  }

  deletePhotoSet(): void {
    this._photoSet.next(undefined);
  }

  storePhotoSetAsFile(photo: File): void {
    this.photoFileSet = photo;
  }

  /**
   * Item tape mark
   */
  storePhotoItemTapeMark(photo: any): void {
    this._photoItemTapeMark.next(photo);
  }

  storePhotoItemTapeMarkAsFile(photo: File): void {
    this.photoFileItemTapeMark = photo;
  }

  getPhotoItemTapeMark(): any {
    return this._photoItemTapeMark.value;
  }

  deletePhotosItemTapeMark(): void {
    this._photoItemTapeMark.next(undefined);
  }

  /**
   * Item state repair
   */
  storePhotoAsFileItemStateRepair(imageAsFile: File): void {
    this.photoFilesItemStateRepair.push(imageAsFile);
  }

  storePhotosItemStateRepair(photo: any, index: number): void {
    this.photosItemStateRepair[index] = photo;
    this._photosItemStateRepair.next(this.photosItemStateRepair);
  }

  deletePhotosItemStateRepair(index: number): void {
    this.photosItemStateRepair[index] = undefined;
    this._photosItemStateRepair.next(this.photosItemStateRepair);
  }

  getPhotosItemStateRepair(): any[] {
    return this.photosItemStateRepair;
  }

  /**
   * Item state replacement
   */
  storePhotoAsFileItemStateReplacement(imageAsFile: File): void {
    this.photoFilesItemStateReplacement.push(imageAsFile);
  }

  storePhotosItemStateReplacement(photo: any, index: number): void {
    this.photosItemStateReplacement[index] = photo;
    this._photosItemStateReplacement.next(this.photosItemStateReplacement);
  }

  deletePhotosItemStateReplacement(index: number): void {
    this.photosItemStateReplacement[index] = undefined;
    this._photosItemStateReplacement.next(this.photosItemStateReplacement);
  }

  getPhotosItemStateReplacement(): any[] {
    return this.photosItemStateReplacement;
  }

  /**
   * Item state superficial issue
   */
  storePhotoAsFileItemStateSuperficialIssue(imageAsFile: File): void {
    this.photoFilesItemStateSuperficialIssue.push(imageAsFile);
  }

  storePhotosItemStateSuperficialIssue(photo: any, index: number): void {
    this.photosItemStateSuperficialIssue[index] = photo;
    this._photosItemStateSuperficialIssue.next(this.photosItemStateSuperficialIssue);
  }

  deletePhotosItemStateSuperficialIssue(index: number): void {
    this.photosItemStateSuperficialIssue[index] = undefined;
    this._photosItemStateSuperficialIssue.next(this.photosItemStateSuperficialIssue);
  }

  getPhotosItemStateSuperficialIssue(): any[] {
    return this.photosItemStateSuperficialIssue;
  }

  /**
   * Item state: in good quality
   */
  storePhotoAsFileItemStateGoodQuality(imageAsFile: File): void {
    this.photoFilesItemStateGoodQuality.push(imageAsFile);
  }

  storePhotosItemStateGoodQuality(photo: string, index: number): void {
    this.photosItemStateGoodQuality[index] = photo;
    this._photosItemStateGoodQuality.next(this.photosItemStateGoodQuality);
  }

  deletePhotosItemStateGoodQuality(index: number): void {
    this.photosItemStateGoodQuality[index] = '';
    this._photosItemStateGoodQuality.next(this.photosItemStateGoodQuality);
  }

  getPhotosItemStateGoodQuality(): string[] {
    return this.photosItemStateGoodQuality;
  }
}
