import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ROUTES } from '@shared/constants/routes/routes.constants';
import { BrowserUtils } from '@azure/msal-browser';
import { ThemeComponent } from '@shared/components/theme/theme.component';
import { AuthGuard } from '@shared/guards/auth.guard';
import { UnauthorisedComponent } from '@shared/components/unauthorised/unauthorised.component';

const routes: Routes = [
  {
    path: ROUTES.PROJECT_OVERVIEWS,
    loadChildren: () => import('@app/modules/projects-overview/projects-overview.module').then((m) => m.ProjectsOverviewModule),
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.PROJECT_DETAILS,
    loadChildren: () => import('./modules/project-details/project-details.module').then((m) => m.ProjectDetailsModule),
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.THEME,
    component: ThemeComponent,
  },
  {
    path: ROUTES.UNAUTHORISED,
    component: UnauthorisedComponent,
  },
  {
    path: ROUTES.HOME,
    redirectTo: ROUTES.PROJECT_OVERVIEWS,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
