import { Injectable } from '@angular/core';
import { PhotoDataDexieService } from '@pwa/indexed-db/dexie-wrapper/photo-data-dexie.service';
import { PromiseExtended, Table } from 'dexie';
import { EntityPhotoState, ItemStatePhoto, RepairStampPhoto } from '@shared/models/offline.model';
import { ItemState, RepairStamp } from '@shared/models/project-details.model';
import { areDatesEqual, createPhotoFileNameAndUpdateEntity, dataURLtoBlob } from '@shared/utilities/function/utils';
import { ItemIdbService } from '@pwa/indexed-db/services/dynamic-data/item/item-idb.service';
import { EMPTY, firstValueFrom, from, Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpService } from '@shared/services/http/http.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RepairStampPhotoService {
  PHOTO_DOWNLOAD_REPAIR_STAMP_ENDPOINT: string = 'photo/download/repair-stamp';
  PHOTO_UPLOAD_URL_REPAIR_STAMP = environment.backendUrl + '/photo/upload/repair-stamp';
  REPAIR_STAMP_PHOTO_OVERWRITE_URL = environment.backendUrl + '/photo/overwrite/repair-stamp';

  /**
   * The `loadCloudPhoto` promise does not ensure idempotence. If the sidebar is opened multiple times, `loadCloudPhoto`
   * is called repeatedly for the same repairStamp, resulting in multiple downloadRepairStampPhoto calls. This is why
   * we cache the calls in this property, not to duplicate them.
   * @private
   */
  private photoCache: Map<string | number, Promise<void>> = new Map();

  private readonly repairStampPhotoStore: Table<RepairStampPhoto, number>;

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private photoDataDexieService: PhotoDataDexieService,
    private itemIdbService: ItemIdbService
  ) {
    this.repairStampPhotoStore = this.photoDataDexieService.repairStampPhoto;
  }

  /**
   * Cloud and offline mixed functions
   */

  /**
   * Conditionally loads a photo from the cloud or offline based on the isOnline parameter.
   * @param repairStamp - The repair stamp object.
   * @param isOnline - Boolean indicating whether to load the photo from the cloud or offline.
   * @returns A promise resolving once the photo is loaded.
   */
  loadPhotoByConnectivity(repairStamp: RepairStamp, isOnline: boolean = true): Promise<void> {
    if (!repairStamp.photoPath) {
      return Promise.reject(new Error('Repair stamp photo path is empty'));
    }
    if (isOnline) {
      return this.loadAndCachePhoto(repairStamp, () => firstValueFrom(this.getCloudPhotoByPhotoPath(repairStamp.photoPath!)));
    } else {
      return this.loadAndCachePhoto(repairStamp, async () => {
        const repairStampPhoto = await this.getOfflineRepairStampPhotoByEntityId(repairStamp.id!);
        if (!repairStampPhoto) {
          return Promise.reject(new Error('Repair stamp photo not found'));
        }
        return repairStampPhoto.photo;
      });
    }
  }

  /**
   * Loads a photo (cloud or offline) and caches the result to avoid duplicate requests.
   * @param repairStamp - The repair stamp containing the photoPath or id.
   * @param fetchPhoto - A function to fetch the photo (cloud or offline).
   * @returns A promise resolving once the photo is loaded.
   */
  private async loadAndCachePhoto(repairStamp: RepairStamp, fetchPhoto: () => Promise<Blob>): Promise<void> {
    const cacheKey = repairStamp.photoPath ?? repairStamp.id!;
    if (!cacheKey) {
      return Promise.resolve();
    }

    if (this.photoCache.has(cacheKey)) {
      return this.photoCache.get(cacheKey)!;
    }

    const photoPromise = (async () => {
      try {
        const image = await fetchPhoto();
        const reader = new FileReader();
        reader.readAsDataURL(image);
        await new Promise((resolve, reject) => {
          reader.onloadend = () => {
            repairStamp.photoInMemory = reader.result as string;
            resolve(null);
          };
          reader.onerror = reject;
        });
      } catch (err) {
        console.error(`Failed to load photo for Repair Stamp: ${repairStamp.id} with path ${repairStamp.photoPath}`, err);
        throw err;
      }
    })();

    // Cache the promise and cleanup after it resolves/rejects
    this.photoCache.set(cacheKey, photoPromise);
    photoPromise.finally(() => this.photoCache.delete(cacheKey));

    return photoPromise;
  }

  /**
   * Cloud only functions
   */

  getCloudPhotoByPhotoPath(photoPath: string) {
    let params = new HttpParams().set('photoPath', photoPath.toString());
    return this.httpService.request(this.PHOTO_DOWNLOAD_REPAIR_STAMP_ENDPOINT).params(params).getBlob();
  }

  uploadPhotosToCloudByRepairStamps(repairStamps: RepairStamp[]): Observable<Object | undefined> {
    const repairStampsHavingPhoto = repairStamps.filter((rs) => rs.photoInMemory && rs.isPhotoModified);
    if (repairStampsHavingPhoto.length === 0) return of(undefined);
    const formData = new FormData();
    repairStamps.forEach((repairStamp) => {
      this.populateFormDataByRepairStamp(repairStamp, formData);
    });

    return this.http.put(this.PHOTO_UPLOAD_URL_REPAIR_STAMP, formData);
  }

  private overwritePhotoInCloud(rsp: RepairStampPhoto): Observable<Object> {
    const formData = new FormData();
    const photoAsFile = new File([rsp.photo], `${rsp.photoFileName}`, { type: 'image/jpeg' });
    formData.append('photo', photoAsFile, rsp.photoFileName);
    formData.append('photoDate', String(rsp.photoDate.toISOString()));
    return this.http.put(this.REPAIR_STAMP_PHOTO_OVERWRITE_URL, formData);
  }

  /**
   * Cloud synchronize only functions
   */

  // This method runs while data having no conflicts or after successful conflicts resolution
  async uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId(projectId: number): Promise<void> {
    const allOfflineCreatedOrUpdatedRepairStampPhotos: RepairStampPhoto[] =
      await this.getAllOfflineRepairStampPhotoByStatusCreatedOrUpdated(projectId);

    const uploadPromises = allOfflineCreatedOrUpdatedRepairStampPhotos.map(async (rsp: RepairStampPhoto) => {
      try {
        if (!rsp.photo || !rsp.photoDate || !rsp.photoFileName || !rsp.entityId) {
          const errorMessage = `Repair Stamp Photo entityId, photo, photoDate, photoFileName must be present but are not. ItemStatePhoto: ${JSON.stringify(rsp)}`;
          console.error(errorMessage);
          return Promise.reject(new Error(errorMessage));
        }

        await firstValueFrom(this.overwritePhotoInCloud(rsp));
        if (rsp.status === EntityPhotoState.CREATED) {
          await firstValueFrom(this.photoDataDexieService.hardDelete(rsp.entityId, this.repairStampPhotoStore));
        } else {
          rsp.status = EntityPhotoState.PRISTINE;
          await firstValueFrom(this.photoDataDexieService.put(rsp, this.repairStampPhotoStore));
        }
      } catch (error) {
        console.error(`Error in uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId()`, error);
      }
    });

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Error in uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId():', error);
    }
  }

  // This method runs while data having no conflicts or after successful conflicts resolution
  async downloadUnSynCloudCreatedOrUpdatedPhotosByProjectId(projectId: number): Promise<void> {
    try {
      const repairStampsByHasPhotoTrueInIndexedDB: RepairStamp[] =
        await this.itemIdbService.getRepairStampsByProjectIdAndHasPhotoTrue(projectId);
      const repairStampsPhotosInIndexedDB: ItemStatePhoto[] = await this.getAllOfflineRepairStampPhotosByStatusNotCreated(projectId);
      const repairStampsHavingUnSyncPhoto: RepairStamp[] = [];
      // Create a map for quick lookup
      const repairStampsPhotosInIndexedDBMap = new Map(repairStampsPhotosInIndexedDB.map((its: RepairStampPhoto) => [its.entityId, its]));

      // Find all cloud Repair Stamps which have unSync photo
      for (const rs of repairStampsByHasPhotoTrueInIndexedDB) {
        // ItemStatePhoto entityID (ItemState ID) of type string means it is offline created
        // if (!rs.photoDate || !rs.id) {
        if (!rs.id) {
          console.error('Cloud Item State ID or photoDate must be present but is empty. Cloud ItemState: ', rs);
          continue;
        }

        const offlineMatchedPhoto: RepairStampPhoto | undefined = repairStampsPhotosInIndexedDBMap.get(rs.id);
        if (
          // If the current offline ItemStatePhoto was not offline updated
          offlineMatchedPhoto?.status !== EntityPhotoState.UPDATED &&
          // and (the current cloud ItemState (and its photo) was cloud created
          (!offlineMatchedPhoto ||
            // or the current cloud ItemState photo was cloud updated)
            !areDatesEqual(rs.photoDate, offlineMatchedPhoto.photoDate))
        )
          repairStampsHavingUnSyncPhoto.push(rs);
      }

      // Create all promises to be resolved
      const promises = repairStampsHavingUnSyncPhoto.map(async (rs: RepairStamp) => {
        try {
          await this.downloadCloudPhotosAndPostToOfflineDatabase(rs);
        } catch (error) {
          console.error(`Error while populating photo for ItemState ${rs.id}:`, error);
        }
      });

      // Download all photos parallel
      await Promise.all(promises);
    } catch (error) {
      console.error('Error in downloadUnSynCloudCreatedOrUpdatedPhotosByProjectId:', error);
    }
  }

  private async downloadCloudPhotosAndPostToOfflineDatabase(rs: RepairStamp): Promise<void> {
    try {
      if (!rs.photoPath) {
        console.error(`Repair Stamp photo path must be present but is not: ${rs.photoPath}`);
        return;
      }

      const photo = await firstValueFrom(this.getCloudPhotoByPhotoPath(rs.photoPath));

      if (!photo) {
        console.error(`Repair Stamp Cloud photo must be present but is not ${rs.photoPath}`);
        return;
      }

      await firstValueFrom(this.postOfflineRepairStampPhotoByRepairStampAndPhotoBlob(rs, photo));
    } catch (error) {
      console.error(`Failed to download photo for Repair Stamp ${rs.id} (photo path: ${rs.photoPath}) from Azure:`, error);
    }
  }

  /**
   * Offline only functions
   */

  getOfflineRepairStampPhotoByEntityId(entityId: number | string): PromiseExtended<RepairStampPhoto | undefined> {
    return this.repairStampPhotoStore.where(this.photoDataDexieService.ENTITY_PHOTO_PRIMARY_KEY).equals(entityId).first();
  }

  putOfflineRepairStampPhotosByItemStates(itemStates: ItemState[], projectId: number, setId: number) {
    const repairStamps: RepairStamp[] = [];
    itemStates.forEach((itemState) => {
      if (itemState.repairStampList) repairStamps.push(...itemState.repairStampList);
    });
    const repairStampsByHasPhotoTrue = repairStamps.filter((rs) => rs.photoInMemory && rs.isPhotoModified);

    if (repairStampsByHasPhotoTrue.length === 0) return of(undefined);
    return this.putOfflineRepairStampPhotosByRepairStamps(repairStampsByHasPhotoTrue, setId, projectId);
  }

  deleteOfflineCreatedOrUpdatedPhotosBySetId(setId: number): Observable<number> {
    return from(
      this.repairStampPhotoStore
        .where('setId')
        .equals(setId)
        .and((x: ItemStatePhoto) => x.status === EntityPhotoState.UPDATED || x.status === EntityPhotoState.CREATED)
        .delete()
    );
  }

  private getAllOfflineRepairStampPhotosByStatusNotCreated(projectId: number): Promise<RepairStampPhoto[]> {
    return this.repairStampPhotoStore
      .where(this.photoDataDexieService.ENTITY_PHOTO_FOREIGN_KEY)
      .equals(projectId)
      .and((rs) => rs.status !== EntityPhotoState.CREATED)
      .toArray();
  }

  private getAllOfflineRepairStampPhotoByStatusCreatedOrUpdated(projectId: number): Promise<RepairStampPhoto[]> {
    return this.repairStampPhotoStore
      .where(this.photoDataDexieService.ENTITY_PHOTO_FOREIGN_KEY)
      .equals(projectId)
      .and((rs) => rs.status === EntityPhotoState.CREATED || rs.status === EntityPhotoState.UPDATED)
      .toArray();
  }

  private postOfflineRepairStampPhotoByRepairStampAndPhotoBlob(rs: RepairStamp, photo: Blob): Observable<number> {
    if (!rs.id || typeof rs.id !== 'number' || !rs.photoPath || !rs.projectId || !rs.setId) {
      console.error(`ItemState ${rs.id} - Invalid ItemState: Missing required properties (id, photoPath, projectId, setId).`);
      return EMPTY;
    }
    const repairStampPhoto: RepairStampPhoto = {
      entityId: rs.id,
      setId: rs.setId,
      status: EntityPhotoState.PRISTINE,
      photoFileName: createPhotoFileNameAndUpdateEntity(rs),
      projectId: +rs.projectId,
      photo: photo,
      photoDate: rs.photoDate ? new Date(rs.photoDate) : new Date(),
    };
    return this.photoDataDexieService.put(repairStampPhoto, this.repairStampPhotoStore);
  }

  private putOfflineRepairStampPhotosByRepairStamps(
    repairStamps: RepairStamp[],
    setId: number | undefined,
    projectId: number | string | undefined
  ) {
    let result: RepairStampPhoto[] = [];
    repairStamps.forEach((rs) => {
      if (!rs.id || !projectId || !setId || !rs.photoInMemory || !rs.photoFileName) {
        console.error(`Invalid !rs.id || !projectId || !setId || !rs.photo || !rs.photoFileName`);
        return undefined;
      }

      const repairStampPhoto: RepairStampPhoto = {
        entityId: rs.id,
        setId: setId,
        status: typeof rs.id === 'number' ? EntityPhotoState.UPDATED : EntityPhotoState.CREATED,
        projectId: +projectId,
        photo: dataURLtoBlob(rs.photoInMemory),
        photoDate: new Date(),
        photoFileName: rs.photoFileName,
      };
      result.push(repairStampPhoto);
    });
    return this.photoDataDexieService.bulkPut(result, this.repairStampPhotoStore);
  }

  /**
   * Helper functions
   */

  private populateFormDataByRepairStamp(repairStamp: RepairStamp, formData: FormData) {
    if (!repairStamp.photoInMemory || !repairStamp.photoFileName) {
      return;
    }
    const photoAsBlob = dataURLtoBlob(repairStamp.photoInMemory);
    const fileName = repairStamp.photoFileName;
    const photoAsFile = new File([photoAsBlob], fileName, { type: 'image/jpeg' });
    formData.append(fileName, photoAsFile, fileName);
  }
}
