import { Injectable } from '@angular/core';
import { ProjectDetailsService } from '@app/modules/project-details/core/services/project-details.service';
import { Observable, take } from 'rxjs';
import { Set } from '@shared/models/project-details.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SetService {
  constructor(private projectDetailsService: ProjectDetailsService) {}

  getAllBySetTitleId(setTitleId: number | string): Observable<Set[]> {
    return this.projectDetailsService
      .getAllSetsBySetTitleId(setTitleId)
      .pipe(map((sets) => sets.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber))));
  }

  getById(setId: number | string): Observable<Set> {
    return this.projectDetailsService.getSet(setId).pipe(take(1));
  }

  put(set: Set): Observable<Set> {
    return this.projectDetailsService.putSet(set).pipe(take(1));
  }

  post(set: Set) {
    return this.projectDetailsService.postSet(set).pipe(take(1));
  }

  delete(setId?: number | string, setIdVersion?: number) {
    return this.projectDetailsService.deleteSet(setId, setIdVersion).pipe(take(1));
  }

  moveSetToNewSetTitle(setTitleId: string | number, body: Set) {
    return this.projectDetailsService.moveSetToNewSetTitle(setTitleId, body).pipe(take(1));
  }

  copyAndMoveSetToNewSetTitle(setTitleId: string | number, body: Set) {
    return this.projectDetailsService.copyAndMoveSetToNewSetTitle(setTitleId, body).pipe(take(1));
  }
}
