import { Injectable } from '@angular/core';
import Dexie, { PromiseExtended, Table } from 'dexie';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { forkJoin, from, map, Observable } from 'rxjs';
import { Project } from '@shared/models/project-overviews.model';
import { InAReference, Item, Set, SetTitle, Speciality } from '@shared/models/project-details.model';
import {
  INA_SYNC_DATA_INDEXED_DB_VERSION,
  INA_SYNC_POST_DATA_INDEXED_DB_NAME,
  ITEM_PRIMARY_KEY,
  PROJECT_PRIMARY_KEY,
  SET_PRIMARY_KEY,
  SET_TITLE_PRIMARY_KEY,
  SPECIALITY_PRIMARY_KEY,
} from '@pwa/constants/offline.constants';

@Injectable({
  providedIn: 'root',
})
export class SyncDataDexieService extends Dexie {
  project!: Table<Project, number>;
  speciality!: Table<Speciality, number>;
  setTitle!: Table<SetTitle, number>;
  set!: Table<Set, number>;
  item!: Table<Item, number>;
  inAReference!: Table<InAReference, number>;

  constructor(readonly responseHandlerService: ResponseHandlerService) {
    super(INA_SYNC_POST_DATA_INDEXED_DB_NAME);
    const db = this;
    // Define tables and indexes
    db.version(INA_SYNC_DATA_INDEXED_DB_VERSION).stores({
      project: `&${PROJECT_PRIMARY_KEY}`,
      speciality: `&${SPECIALITY_PRIMARY_KEY}, &name, projectId`,
      setTitle: `&${SET_TITLE_PRIMARY_KEY}, specialityId, projectId`,
      set: `&${SET_PRIMARY_KEY}, titleId, projectId`,
      item: `&${ITEM_PRIMARY_KEY}, parentOfItemId, projectId`,
      inAReference: `++${ITEM_PRIMARY_KEY}, projectId`,
    });
  }

  async initDb(): Promise<PromiseExtended<Dexie>> {
    // Dexie handles the creation and upgrading of stores automatically.
    // Additional initialization logic can go here if needed.
    // Since Dexie uses Promises, you can just return the Dexie database open promise.
    await this.open();
    return this;
  }

  // global logics
  public insertOrReplace<T>(entity: T, table: Table<T, number | string>): Observable<number | string> {
    return from(table.put(entity));
  }

  public hardDelete<T>(key: number | string, table: Table<T, number | string>): Observable<void> {
    return from(table.delete(key));
  }

  public bulkPostItems(entity: Item[]) {
    return from(this.item.bulkPut(entity));
  }

  public bulkDeleteItems(itemIds: number[]) {
    return from(this.item.bulkDelete(itemIds));
  }

  public bulkDeleteInAReferences(ids: number[]) {
    return from(this.inAReference.bulkDelete(ids));
  }

  postSpeciality(speciality: Speciality) {
    this.logDebug(`Post Speciality with ID: ${speciality.id}`);
    return this.insertOrReplace(speciality, this.speciality);
  }

  postSetTitle(setTitle: SetTitle) {
    this.logDebug(`Post SetTitle with ID: ${setTitle.id}`);
    return this.insertOrReplace(setTitle, this.setTitle);
  }

  postSet(set: Set) {
    this.logDebug(`Post SetTitle with ID: ${set.id}`);
    return this.insertOrReplace(set, this.set);
  }

  postItem(item: Item) {
    this.logDebug(`Post Item with ID: ${item.id}`);
    return this.insertOrReplace(item, this.item);
  }

  storeInAReference(inAReference: InAReference) {
    this.logDebug(`Post InAReference with ID: ${inAReference.id}`);
    return this.insertOrReplace(inAReference, this.inAReference);
  }

  hardDeleteSpeciality(key: number | string) {
    this.logDebug(`Delete Speciality with ID: ${key}`);
    return this.hardDelete(key, this.speciality);
  }

  hardDeleteSetTitle(key: number | string) {
    this.logDebug(`Delete SetTitle with ID: ${key}`);
    return this.hardDelete(key, this.setTitle);
  }

  hardDeleteSet(key: number | string) {
    this.logDebug(`Delete Set with ID: ${key}`);
    return this.hardDelete(key, this.set);
  }

  public queryTotalRecords(projectId: number): Observable<number> {
    const projectCount$ = from(this.project.where('id').equals(projectId).count());
    const specialityCount$ = from(this.speciality.where('projectId').equals(projectId).count());
    const setTitleCount$ = from(this.setTitle.where('projectId').equals(projectId).count());
    const setCount$ = from(this.set.where('projectId').equals(projectId).count());
    const itemCount$ = from(this.item.where('projectId').equals(projectId).count());
    const inAReferenceCount$ = from(this.inAReference.where('projectId').equals(projectId).count());

    return forkJoin([projectCount$, specialityCount$, setTitleCount$, setCount$, itemCount$, inAReferenceCount$]).pipe(
      map((counts) => counts.reduce((acc, count) => acc + count, 0))
    );
  }

  public isSyncDbEmpty(projectId: number | string): Observable<boolean> {
    const projectCount$ = from(this.project.where('id').equals(Number(projectId)).count()).pipe(map((count) => count === 0));
    const specialityCount$ = from(this.speciality.where('projectId').equals(Number(projectId)).count()).pipe(map((count) => count === 0));
    const setTitleCount$ = from(this.setTitle.where('projectId').equals(Number(projectId)).count()).pipe(map((count) => count === 0));
    const setCount$ = from(this.set.where('projectId').equals(Number(projectId)).count()).pipe(map((count) => count === 0));
    const itemCount$ = from(this.item.where('projectId').equals(Number(projectId)).count()).pipe(map((count) => count === 0));

    return forkJoin([projectCount$, specialityCount$, setTitleCount$, setCount$, itemCount$]).pipe(
      map((counts) => counts.every((count) => count))
    );
  }

  public clearStoresByProjectId(projectId: number | string) {
    return forkJoin([
      from(this.project.where('id').equals(projectId).delete()),
      from(this.speciality.where('projectId').equals(projectId).delete()),
      from(this.setTitle.where('projectId').equals(projectId).delete()),
      from(this.set.where('projectId').equals(projectId).delete()),
      from(this.item.where('projectId').equals(projectId).delete()),
      from(this.inAReference.where('projectId').equals(projectId).delete()),
    ]);
  }

  // helper methods
  private logDebug(message?: string) {
    // console.debug('[indexed-db-sync-data.service.ts]: ' + message + '.');
  }
}
