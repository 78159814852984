import { Subscription } from 'rxjs';
import { Item, ItemState, RepairStamp, Set } from '@shared/models/project-details.model';
import { v4 as uuidv4 } from 'uuid';
import { PHOTO_PATH, TEMPORARY_PHOTO_ID_PREFIX } from '@shared/constants/utilities.constants';

export function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

/**
 * This function iterates over an array of Subscription objects and unsubscribes
 * from each one that is not already closed.
 *
 * @param {Subscription} subs
 */
export function unsub(subs: Subscription[]): void {
  if (subs === undefined || subs.length === 0) return;

  for (const sub of subs) {
    if (sub && !sub.closed) {
      sub.unsubscribe();
    }
  }
}

/**
 * Photo functions.
 * Example: PHOTO_PATH_project_1/set_1/photo_set_1.jpg, TEMPORARY_PHOTO_ID_q67adkasdasxas2
 */
export function createPhotoFileNameAndUpdateEntity(entity: RepairStamp | ItemState | Item | Set): string {
  let temporaryPhotoId: string | number;
  let fileName: string;

  if (entity.id && typeof entity.id === 'number') {
    if (entity.photoPath) fileName = `${PHOTO_PATH}_${entity.photoPath}`;
    else {
      temporaryPhotoId = `${uuidv4()}`;
      fileName = `${TEMPORARY_PHOTO_ID_PREFIX}_${temporaryPhotoId}`;
      entity.temporaryPhotoId = temporaryPhotoId;
    }
  } else {
    temporaryPhotoId = `${uuidv4()}`;
    fileName = `${TEMPORARY_PHOTO_ID_PREFIX}_${temporaryPhotoId}`;
    entity.temporaryPhotoId = temporaryPhotoId;
  }

  return fileName;
}

/**
 * Remove temporary, string-typed, generated by uuid, IDs of Item State.
 * These IDs are used in offline mode to map Item State and Repair Stamps.
 * They should be removed to be recognized as unsynced data by the Backend.
 *
 * Item State IDs of type number are IDs from Backend, assuming everything is correct
 * @param item
 */
export function removeTemporaryIdsOfItemStatesAndRepairStamps(item: Item) {
  if (!item.itemStates) return;

  for (const itemState of item.itemStates) {
    if (typeof itemState.id !== 'number') {
      itemState.id = undefined;
    }
    removeRepairStampTemporaryIds(itemState);
  }
}

/**
 * Remove temporary, string-typed, generated by uuid, IDs of Repair Stamp.
 * These IDs are used in offline mode to map photo of Repair Stamps.
 * They should be removed to be recognized as unsynced data by the Backend.
 *
 * Repair Stamp IDs of type number are IDs from Backend, assuming everything is correct
 * @param itemState
 */
export function removeRepairStampTemporaryIds(itemState: ItemState) {
  if (!itemState.repairStampList) return;

  for (const rs of itemState.repairStampList) {
    if (typeof rs.id !== 'number') {
      rs.id = undefined;
    }
  }
}

/**
 * Modify the array to include the given id.
 * @param array
 * @param id
 */
export function addIdToArray(array: string[], id: string): void {
  if (!array.includes(id)) {
    array.push(id);
  }
}

/**
 * Modify the array to exclude the given id.
 * @param array
 * @param id
 */
export function removeIdFromArray(array: string[], id: string): void {
  const index = array.indexOf(id);
  if (index !== -1) {
    array.splice(index, 1);
  }
}

/**
 * Download file by file path and optionally set file name.
 * @param filePath
 * @param fileName
 */
export const downloadFileByFilePath = (filePath: string, fileName?: string) => {
  const link = document.createElement('a');
  link.setAttribute('type', 'hidden');
  link.href = filePath;
  link.download = fileName ? fileName : '';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Removes the file extension from a given file name.
 * @param {string} fileName - The full file name, including the extension
 * @returns {string} - The file name without the extension
 */
export function removeFileExtension(fileName: string): string {
  const lastDotIndex = fileName.lastIndexOf('.');
  return lastDotIndex !== -1 ? fileName.substring(0, lastDotIndex) : fileName;
}

/**
 * Function to calculate the difference in days between today and a target date in format DD-MM-YYYY.
 * @param {string} targetDate
 */
export function calculateDaysDifference(targetDate: string): number {
  if (!targetDate || typeof targetDate !== 'string') return NaN;

  // Ensure the date string is in the format DD-MM-YYYY
  const dateParts = targetDate.split('-');
  if (dateParts.length !== 3) {
    console.error('Invalid date format: expected DD-MM-YYYY.');
    // Return NaN for incorrect format
    return NaN;
  }

  // Parse the target date (format: DD-MM-YYYY)
  const [day, month, year] = dateParts.map(Number);
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    console.error('Invalid date: day, month, or year is not a number.');
    // Return NaN if parsing failed
    return NaN;
  }

  // Create a Date object for the target date
  const target = new Date(year, month - 1, day);

  // Check if the date is valid
  if (target.getDate() !== day || target.getMonth() !== month - 1 || target.getFullYear() !== year) {
    console.error('Invalid date: date does not exist.');
    // Return NaN for an invalid date
    return NaN;
  }

  // Get today's date without the time component
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds
  const diffInMilliseconds: number = today.getTime() - target.getTime();

  // Convert the difference to days
  const diffInDays: number = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  return diffInDays;
}

/**
 * Compare Backend and Frontend dates to determine if they are equal
 * @param {string} backendDateAsString
 * @param {Date} frontendDate
 */
export function areDatesEqual(backendDateAsString: string | undefined, frontendDate: Date): boolean {
  if (!backendDateAsString) {
    console.info('Photo data is missing for the current Entity');
    return false;
  }
  const backendDate = new Date(backendDateAsString);
  // Normalize both dates to UTC by using their timestamps
  const backendTimestamp = backendDate.getTime();
  const currentTimestamp = frontendDate.getTime();

  // Compare the timestamps
  return backendTimestamp === currentTimestamp;
}

export function dataURLtoBlob(dataUrl: string): Blob {
  const byteString = atob(dataUrl.split(',')[1]);
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}
