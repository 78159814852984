import { Injectable, OnDestroy } from '@angular/core';
import { Set, SetTitle } from '@shared/models/project-details.model';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { Table } from 'dexie';
import { catchError, from, map, Observable, of, Subscription, switchMap, tap, throwError } from 'rxjs';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { SYNC_CREATE_DATA_VERSION_VALUE } from '@pwa/constants/offline.constants';
import { SyncDataDexieService } from '@pwa/indexed-db/dexie-wrapper/sync-data-dexie.service';
import { translate, TranslocoService } from '@ngneat/transloco';
import { OfflineResponseErrorType } from '@pwa/enums/OfflineResponseErrorType';
import { ErrorResponse } from '@shared/models/error.model';

@Injectable({
  providedIn: 'root',
})
export class SetIdbService implements OnDestroy {
  private readonly setStore: Table<Set, number>;
  private SET_STORE_NAME = 'set';

  translocoSub: Subscription;

  constructor(
    private projectDataIndexedDbService: ProjectDataDexieService,
    private syncDataIndexedDbService: SyncDataDexieService,
    private readonly responseHandlerService: ResponseHandlerService,
    private translocoService: TranslocoService
  ) {
    this.setStore = this.projectDataIndexedDbService.table(this.SET_STORE_NAME);

    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  /**
   * Persists Sets into the IndexedDB store.
   *
   * @param {Set[]} sets - The list of sets to be persisted.
   * @returns {Observable<string | number>} - An observable that resolves when persistence is complete.
   */
  postSets(sets: Set[]): Observable<string | number> {
    return this.projectDataIndexedDbService.bulkPost(sets, this.setStore).pipe(
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-creation-of-set-failed'));
        console.error(translate('snacks.offline-creation-of-set-failed'), error);
        return throwError(() => error);
      })
    );
  }

  checkIfSetExistsBySerialNumberAndTitleId(serialNumber: string, titleId: number): Observable<boolean> {
    return this.projectDataIndexedDbService.setExists(serialNumber, titleId).pipe(
      switchMap((setExists: boolean) => {
        if (setExists) {
          return throwError(() => {
            const error: ErrorResponse = {
              error: {
                type: OfflineResponseErrorType.DuplicatedEntity,
              },
            };
            return error;
          });
        }
        return of(false);
      })
    );
  }

  post(set: Set, projectId?: number, parent?: SetTitle) {
    return this.projectDataIndexedDbService.getLatestRecordId(this.setStore).pipe(
      switchMap((latestRecordId) => {
        if (latestRecordId === undefined || latestRecordId === null) {
          return throwError(() => 'LatestRecordId is invalid');
        }
        const newId = latestRecordId + 1;
        const offlineSet = {
          ...set,
          id: newId,
          titleId: set.titleId,
          serialNumber: set.serialNumber,
          version: SYNC_CREATE_DATA_VERSION_VALUE,
          deleted: false,
          projectId: Number(projectId),
          hasCloudParent: parent?.version !== SYNC_CREATE_DATA_VERSION_VALUE,
        } as Set;

        return this.projectDataIndexedDbService.insertOrReplace(offlineSet, this.setStore).pipe(
          switchMap((id: number | string) => this.getById(id)),
          tap((result) => {
            if (!result) {
              console.error(translate('snacks.offline-get-set-by-id-failed'), result);
              return;
            }

            this.syncDataIndexedDbService.postSet(result).subscribe({
              error: (error) => {
                console.error(translate('snacks.offline-creation-of-set-failed'), error);
              },
            });
          }),
          catchError((error) => {
            console.error(translate('snacks.offline-creation-of-set-failed'), error);
            return throwError(() => error);
          })
        );
      })
    );
  }

  /**
   * Check for duplication of a Set in a Set Title,
   * update a Set in the ina_project_data
   * and upon success store the result in ina_sync_data
   * @param set
   * @param projectId
   */
  put(set: Set, projectId?: number) {
    const offlineSet = {
      ...set,
      titleId: set.titleId,
      projectId: Number(projectId),
      weight: Number(set.weight),
    } as Set;

    return this.projectDataIndexedDbService.insertOrReplace(offlineSet, this.setStore).pipe(
      switchMap((id: number | string) => this.getById(id)),
      tap((result) => {
        if (result) {
          this.syncDataIndexedDbService.postSet(result).subscribe({
            error: (error) => {
              console.error(translate('snacks.offline-update-of-set-failed'), error);
            },
          });
        }
      }),
      catchError((error) => {
        console.error(translate('snacks.offline-update-of-set-failed'), error);
        return throwError(() => error);
      })
    );
  }

  getById(id: number | string): Observable<Set | undefined> {
    return from(this.setStore.where('id').equals(id).first()).pipe(
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-get-set-by-id-failed'));
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Upload a photo as a field directly in IndexedDB
   * Occur during downloading all Project photos process for offline mode
   * @param offlineSet
   */
  uploadPhoto(offlineSet: Set): Observable<string | number> {
    return this.projectDataIndexedDbService.insertOrReplace(offlineSet, this.setStore).pipe(
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-upload-photo-failed'));
        console.error(translate('snacks.offline-upload-photo-failed'), error);
        return throwError(() => error);
      })
    );
  }

  getAllSetsBySetTitleId(titleId: number | string): Observable<Set[]> {
    return from(
      this.setStore
        .where('titleId')
        .equals(titleId)
        .and((x: any) => !x.deleted)
        .toArray()
    ).pipe(
      map((sets) => sets.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber))),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-getting-of-all-sets-failed'));
        console.error(translate('snacks.offline-getting-of-all-sets-failed'), error);
        return throwError(() => error);
      })
    );
  }

  delete(setId: number | string, projectId?: number) {
    return this.getById(setId).pipe(
      switchMap((set) => {
        const deleteSet = {
          ...set,
          deleted: true,
        } as Set;
        /*
        version = -1, it means this Set was created in offline mode and can be hardDelete.
        version >=0, it means this Sets existed in cloud mode and must be put (make deleted = true) to be explicitly synchronized later
         */
        return deleteSet.version === SYNC_CREATE_DATA_VERSION_VALUE ? this.hardDelete(deleteSet.id) : this.put(deleteSet, projectId);
      })
    );
  }

  hardDelete(setId?: number | string): Observable<any> {
    if (setId) {
      return this.projectDataIndexedDbService.hardDelete(setId, this.setStore).pipe(
        tap(() => {
          this.syncDataIndexedDbService.hardDeleteSet(setId).subscribe({
            error: (error) => {
              console.error(translate('snacks.offline-deletion-of-set-title-failed'), error);
            },
          });
        }),
        catchError((error) => {
          this.responseHandlerService.handleError(translate('snacks.offline-deletion-of-set-title-failed'));
          console.error(translate('snacks.offline-deletion-of-set-title-failed'), error);
          return throwError(() => error);
        })
      );
    } else {
      return throwError(() => new Error('Speciality is null or idbKey is undefined.'));
    }
  }

  /**
   * Start synchronization services
   */

  putAndUpdateVersion(set: Set) {
    const offlineSet = { ...set };

    return this.syncDataIndexedDbService.postSet(offlineSet).pipe(
      switchMap(() => this.projectDataIndexedDbService.insertOrReplace(offlineSet, this.setStore)),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-update-of-set-failed'));
        console.error(translate('snacks.offline-update-of-set-failed'), error);
        return throwError(() => error);
      })
    );
  }

  patch(set: Set): Observable<number | string> {
    return this.getById(set.id!).pipe(
      switchMap((idbSet) => {
        const patchedSet = { ...idbSet, ...set };
        return this.syncDataIndexedDbService.postSet(patchedSet);
      }),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-update-of-set-failed'));
        console.error(translate('snacks.offline-update-of-set-failed'), error);
        return throwError(() => error);
      })
    );
  }

  /**
   * End synchronization services
   */
}
