import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HeaderConfig } from '@shared/interfaces/header-config.interface';

@Injectable({
  providedIn: 'root',
})
export class HeaderConfigService {
  headerConfigSubject: BehaviorSubject<HeaderConfig> = new BehaviorSubject<HeaderConfig>({
    title: 'Aesculap Inventory Analysis',
    showBack: true,
    navigationDrawerMode: false,
  });

  shouldDisplaySpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  spinnerTooltip: BehaviorSubject<string> = new BehaviorSubject('');
}
