import { Injectable } from '@angular/core';
import { Table } from 'dexie';
import { EntityPhotoState, ItemPhoto, ItemStatePhoto } from '@shared/models/offline.model';
import { PhotoDataDexieService } from '@pwa/indexed-db/dexie-wrapper/photo-data-dexie.service';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { catchError, EMPTY, firstValueFrom, from, Observable, of } from 'rxjs';
import { Item, ItemState } from '@shared/models/project-details.model';
import { areDatesEqual, createPhotoFileNameAndUpdateEntity } from '@shared/utilities/function/utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpService } from '@shared/services/http/http.service';
import { environment } from '@environments/environment';
import { PhotoStoreService } from '@app/modules/project-details/core/services/photo-store.service';
import { ItemIdbService } from '@pwa/indexed-db/services/dynamic-data/item/item-idb.service';

@Injectable({
  providedIn: 'root',
})
export class ItemPhotoService {
  PHOTO_DOWNLOAD_URL_ITEM_TAPE_MARK = 'photo/download/item-tape-mark';
  PHOTO_UPLOAD_URL_ITEM_TAPE_MARK = environment.backendUrl + '/photo/upload/item-tape-mark';

  private readonly itemPhotoStore: Table<ItemPhoto, number>;

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private itemIdbService: ItemIdbService,
    private photoStore: PhotoStoreService,
    private photoDataDexieService: PhotoDataDexieService,
    readonly snacks: ResponseHandlerService
  ) {
    this.itemPhotoStore = this.photoDataDexieService.itemPhoto;
  }

  /**
   * Cloud functions
   */

  getCloudPhotoByPhotoPath(photoPath: string | undefined): Observable<Blob | undefined> {
    if (!photoPath) {
      console.error('Photo path for Item tape mark is empty');
      return of(undefined);
    }
    let params = new HttpParams().set('photoPath', photoPath.toString());
    return this.httpService
      .request(this.PHOTO_DOWNLOAD_URL_ITEM_TAPE_MARK)
      .params(params)
      .getBlob()
      .pipe(catchError(this.handleError('cloudGetItemTapeMarkPhoto')));
  }

  uploadCloudPhotoByPhotoInMemory(): Observable<Object | undefined> {
    const formData = new FormData();
    const file = this.photoStore.photoFileItemTapeMark;

    if (!file) {
      return of(undefined);
    }
    formData.append(`photo`, file, file.name);

    return this.http.put(this.PHOTO_UPLOAD_URL_ITEM_TAPE_MARK, formData);
  }

  /**
   * @param offlineItemPhotoFileName is used in cloud to search the respected Item and map Item and its photo together
   * @param photo is the actual photo
   */
  uploadCloudPhotoByFileName(offlineItemPhotoFileName: string, photo: Blob): Observable<Object> {
    const formData = new FormData();
    const photoAsFile = new File([photo!], `${offlineItemPhotoFileName}`, { type: 'image/jpeg' });
    formData.append('photo', photoAsFile, photoAsFile.name);

    return this.http.put(this.PHOTO_UPLOAD_URL_ITEM_TAPE_MARK, formData);
  }

  /**
   * Cloud synchronize functions
   */

  // This method runs while data having no conflicts or after successful conflicts resolution
  async downloadUnSyncCloudCreatedOrUpdatedPhotosByProjectId(projectId: number): Promise<void> {
    try {
      const allOfflineItemsByHasPhotoTrue: Item[] = await this.itemIdbService.getItemsByProjectIdAndHasPhotoTrue(projectId);
      const allOfflineItemPhotos: ItemPhoto[] = await this.getAllOfflineItemPhotosByStatusNotCreated(projectId);
      // Create a map for quick lookup
      const allOfflineItemStatePhotosMap = new Map(allOfflineItemPhotos.map((ip: ItemPhoto) => [ip.entityId, ip]));
      const itemsHavingUnSyncPhoto: Item[] = [];

      // Find all cloud Item States which have unSync photo
      for (const item of allOfflineItemsByHasPhotoTrue) {
        if (!item.photoDate) {
          console.error('Cloud Item State ID or photoDate must be present but is empty. Cloud ItemState: ', item);
          continue;
        }

        const offlineMatchItemStatePhoto: ItemStatePhoto | undefined = allOfflineItemStatePhotosMap.get(item.id);
        if (
          // If the current offline ItemPhoto was not offline updated
          offlineMatchItemStatePhoto?.status !== EntityPhotoState.UPDATED &&
          // and (the current cloud Item (and its photo) was cloud created
          (!offlineMatchItemStatePhoto ||
            // or the current cloud ItemState photo was cloud updated)
            !areDatesEqual(item.photoDate, offlineMatchItemStatePhoto.photoDate))
        )
          itemsHavingUnSyncPhoto.push(item);
      }

      // Create all promises to be resolved
      const promises = itemsHavingUnSyncPhoto.map(async (item) => {
        try {
          await this.downloadCloudPhotoAndPersistToOfflineDatabase(item);
        } catch (error) {
          console.error(`Error while populating photo for ItemState ${item.id}:`, error);
        }

        // Download all photos parallel
        await Promise.all(promises);
      });
    } catch (error) {
      console.error(`Failed to getAndPersistSetPhotos()`, error);
    }
  }

  async uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId(projectId: number): Promise<void> {
    const allOfflineCreatedOrUpdatedItemStatePhotos: ItemPhoto[] = await this.getAllOfflineCreatedOrUpdatedItemPhotos(projectId);

    const uploadPromises = allOfflineCreatedOrUpdatedItemStatePhotos.map(async (itemPhoto: ItemPhoto) => {
      try {
        await firstValueFrom(this.uploadCloudPhotoByFileName(itemPhoto.photoFileName, itemPhoto.photo));

        if (itemPhoto.status === EntityPhotoState.CREATED) {
          await firstValueFrom(this.photoDataDexieService.hardDelete(itemPhoto.entityId, this.itemPhotoStore));
        } else {
          itemPhoto.status = EntityPhotoState.PRISTINE;
          await firstValueFrom(this.photoDataDexieService.put(itemPhoto, this.itemPhotoStore));
        }
      } catch (error) {
        console.error(`Error in uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId()`, error);
      }
    });

    try {
      // Upload all photos parallel
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Error in uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId():', error);
    }
  }

  private async downloadCloudPhotoAndPersistToOfflineDatabase(item: Item) {
    if (!item.photoDate || !item.photoPath) {
      console.error(
        `Item photoDate or photoPath must be valid but is not. Id: ${item.id}, photoDate: ${item.photoDate}, photoPath: ${item.photoPath}`
      );
      return;
    }

    const cloudPhoto = await firstValueFrom(this.getCloudPhotoByPhotoPath(item.photoPath));
    if (!cloudPhoto) {
      console.error(`Cloud photo for Item ${item.id} must be present but is empty`);
      return;
    }

    await firstValueFrom(this.postOfflineItemPhoto(item, cloudPhoto));
  }

  /**
   * Offline functions
   */

  getOfflineItemPhotoByEntityId(itemId: number): Observable<ItemPhoto | undefined> {
    return from(this.itemPhotoStore.where(this.photoDataDexieService.ENTITY_PHOTO_PRIMARY_KEY).equals(itemId).first()).pipe(
      catchError(this.handleError('getByEntityId'))
    );
  }

  deleteAllOfflineCreatedOrUpdatedPhotosBySetId(setId: number): Observable<number> {
    return from(
      this.itemPhotoStore
        .where('setId')
        .equals(setId)
        .and((x: ItemPhoto) => x.status === EntityPhotoState.UPDATED || x.status === EntityPhotoState.CREATED)
        .delete()
    ).pipe(catchError(this.handleError('deleteAllBySetId')));
  }

  putOfflineItemPhoto(item: Item, photo: Blob, projectId: number, editMode: boolean): Observable<number> {
    if (!item.id || !item.photoFileName) {
      console.error('Item ID or photoFileName is invalid');
      return EMPTY;
    }
    const itemPhoto: ItemPhoto = {
      entityId: item.id,
      setId: item.parentOfItemId,
      photo: photo,
      status: editMode ? EntityPhotoState.UPDATED : EntityPhotoState.CREATED,
      photoFileName: item.photoFileName,
      projectId: +projectId,
      photoDate: new Date(),
    };
    return this.photoDataDexieService.put(itemPhoto, this.itemPhotoStore);
  }

  private postOfflineItemPhoto(item: Item, photo: Blob): Observable<number> {
    if (!item.id || !item.photoPath || !item.projectId || !item.photoDate) {
      console.error(`Item - Invalid ItemState: Missing required properties (id, photoPath, projectId, photoDate).`, item);

      return EMPTY;
    }
    const itemPhoto: ItemPhoto = {
      entityId: item.id,
      setId: item.parentOfItemId,
      photo: photo,
      status: EntityPhotoState.PRISTINE,
      projectId: item.projectId!,
      photoFileName: createPhotoFileNameAndUpdateEntity(item),
      photoDate: new Date(item.photoDate),
    };
    return this.photoDataDexieService.put(itemPhoto, this.itemPhotoStore);
  }

  private getAllOfflineCreatedOrUpdatedItemPhotos(projectId: number): Promise<ItemPhoto[]> {
    return this.itemPhotoStore
      .where(this.photoDataDexieService.ENTITY_PHOTO_FOREIGN_KEY)
      .equals(projectId)
      .and((ip) => ip.status === EntityPhotoState.CREATED || ip.status === EntityPhotoState.UPDATED)
      .toArray();
  }

  private getAllOfflineItemPhotosByStatusNotCreated(projectId: number): Promise<ItemPhoto[]> {
    return this.itemPhotoStore
      .where(this.photoDataDexieService.ENTITY_PHOTO_FOREIGN_KEY)
      .equals(projectId)
      .and((ip) => ip.status !== EntityPhotoState.CREATED)
      .toArray();
  }

  /**
   * Helper functions
   */

  private handleError(methodName: string): (error: any) => never {
    return (error: any) => {
      console.error(`Error in ItemPhotoService.${methodName}:`, error);
      throw error;
    };
  }
}
