import { Item, ItemState, ItemStateCategory, Reason, ReasonCategory } from '@shared/models/project-details.model';
import { ProjectReasonsDTO } from '@shared/models/static-data.model';

export interface OfflineStaticDataDTO {
  reasons: Reason[];
  categories: ItemStateCategory[];
  categoriesReasons: ReasonCategory[];
  itemStateCategory: ItemStateCategory[];
  defaultProjectCategoryReasons: ProjectReasonsDTO[];
  projectCategoryReasons: ProjectCategoryReason[];
}

export interface EMDNEntity {
  id?: number;
  emdnCode?: string;
  emdnDescription?: string;
  groupQualityCriteria?: string;
}

export interface ProjectCategoryReason {
  id?: number;
  projectId?: number;
  categoryId: number;
  reasonId: number;
  isManual?: boolean;
  isDisabled?: boolean;
}

export interface EntityPhoto {
  entityId: number; // entityId is the ID of the original entity
  projectId: number;
  photo: Blob;
  photoFileName: string; // Used for searching the (associated) entity in cloud database
  photoDate: Date; // Used to detect photo changes of cloud and offline data
}

export interface SetPhoto extends EntityPhoto {
  status: EntityPhotoState; // Used for synchronization to either re-download or keep the entity photo
}

export enum EntityPhotoState {
  // CREATED and UPDATED result in re-downloading the entity photo
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  PRISTINE = 'PRISTINE', // PRISTINE means no change,  result in no re-downloading the entity photo
}

export interface ItemPhoto extends EntityPhoto {
  setId: number; // Used to delete or upload all ItemPhotos by setId
  status: EntityPhotoState;
}

export interface ItemStatePhoto extends Omit<ItemPhoto, 'entityId'> {
  entityId: number | string; // entityId of type number means the entity was cloud created, of type string means offline created
}

export interface RepairStampPhoto extends ItemStatePhoto {}
