import { createAction, props } from '@ngrx/store';
import { User } from '@shared/interfaces/user.interface';
import { GenericErrorAction } from '@app/store/models/GenericErrorAction';

// Action to handle generic errors
export const genericErrorAction = createAction('[APP] GENERIC_ERROR', props<GenericErrorAction>());

/** Loading status */
// Action to start the global loading state
export const startLoadingAction = createAction('[APP] START GLOBAL_LOADING');
// Action to stop the global loading state
export const stopLoadingAction = createAction('[APP] STOP GLOBAL_LOADING');

/** User data */
// Action to load user data
export const loadUserDataAction = createAction('[APP] LOAD_USER_DATA');
// Action dispatched when user data is successfully loaded
export const loadUserDataSuccessAction = createAction('[APP] LOAD_USER_DATA_SUCCESS', props<{ payload: { user: User } }>());
// Action dispatched when the app initially loads to set the User's app language to `en`, or when the User changes it
export const setApplicationLanguage = createAction('[LANGUAGE_PICKER] SET_APPLICATION_LANGUAGE', props<{ appLanguage: string }>());

/** Online/offline status */
export const toggleNetworkStatusAction = createAction('[App] TOGGLE_NETWORK_ONLINE_OFFLINE_STATUS');

// Action to check network status
export const checkNetworkStatusAction = createAction('[APP] CHECK_NETWORK_STATUS');
// Action to set the online status
export const setIsOnlineAction = createAction('[APP] SET_IS_ONLINE', props<{ payload: { isOnline: boolean } }>());
// Action to indicate going offline
export const goOfflineAction = createAction('[APP] GO_OFFLINE');
