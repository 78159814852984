import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'aia-number-fc',
  templateUrl: './number-fc.component.html',
  styleUrls: ['./number-fc.component.sass'],
})
export class NumberFcComponent implements OnChanges, OnInit, AfterViewInit {
  @ViewChild('numberInput') numberInput: ElementRef | undefined;

  @Input() fc: FormControl<number> = new FormControl();
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() tabIndex: number = -1;
  @Input() showButtons: boolean = true;
  @Input() ariaLabel?: string;
  @Input() placeholder?: string;
  @Input() shouldFocus?: boolean = false;
  @Input() shouldGoHigherThanTheMaximum?: boolean = true;

  constructor(private snacks: ResponseHandlerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Enable or disable the FormControl based on the disabled input
    if (changes && changes['disabled'] !== undefined) {
      this.disabled ? this.fc.disable() : this.fc.enable();
    }

    // Focus the input based on the `shouldFocus` input
    if (changes['shouldFocus'] && changes['shouldFocus'].currentValue === true) {
      if (this.numberInput) this.numberInput.nativeElement.focus();
    }
  }

  ngOnInit(): void {
    this.fc.valueChanges.subscribe((newValue: number) => {
      // If `shouldGoHigherThanTheMaximum` is set to false, subscribe to the form control's value changes and
      // if they exceed the `max` value, set the form control's value to the `max` value.
      // Example - `max` is 10, someone inputs 42 - set the form control's value to 10.
      if (!this.shouldGoHigherThanTheMaximum) {
        if (newValue > this.max && this.max > 0) {
          this.fc.setValue(this.max, { emitEvent: false });
          this.snacks.handleInfo(translate('snacks.cannot-enter-a-number-higher-than-the-quantity'));
        }
      }

      // If the User tries to enter/paste a negative value, reset it to the `min` value and show an error snack
      if (newValue < this.min) {
        this.fc.setValue(this.min, { emitEvent: false });
        this.snacks.handleInfo(translate('snacks.please-enter-a-number-greater-than-or-equal-min', { min: this.min }));
      }
    });
  }

  ngAfterViewInit(): void {
    // Focus the input element if shouldFocus is true
    if (this.shouldFocus && this.numberInput) {
      this.numberInput.nativeElement.focus();
    }
  }

  // Preventing the ENTER, the minus and the `e` key so that no shenanigans are occurring during filling of the input
  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  // Increase the value of the FormControl, ensuring it does not exceed the max
  increase(): void {
    if (this.fc.value < this.max) {
      this.fc.setValue(this.fc.value + 1);
    }
  }

  // Decrease the value of the FormControl, ensuring it does not go below the min
  decrease(): void {
    if (this.fc.value > this.min) {
      this.fc.setValue(this.fc.value - 1);
    }
  }
}
