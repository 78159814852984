import { Environment } from '@shared/interfaces/environment.interface';

export const environment: Environment = {
  production: false,
  environment: 'qas',
  enableProdMode: false,
  backendUrl: 'https://ina.iaa.qas-bbrauncloud.com/bbraun/inventory',
  msalConfig: {
    auth: {
      clientId: '35baaee1-8a8f-4248-9cf6-42a38109feaa',
      redirectUri: 'https://ina.iaa.qas-bbrauncloud.com',
    },
  },
  apiConfig: {
    scopes: ['https://test.account.bbraun.com/406e5d7d-b325-422d-90d7-7d3cbccdaa34/read'],
    uri: 'https://ina.iaa.qas-bbrauncloud.com/bbraun/inventory',
  },
  protected: ['https://ina.iaa.qas-bbrauncloud.com/bbraun/inventory'],
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1A__SIGNUP_SIGNIN_MFa',
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/',
      },
    },
    authorityDomain: 'https://test.account.bbraun.com', //known authority
    authorityMetadata: {
      authorization_endpoint:
        'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/oauth2/v2.0/authorize?p=B2C_1A__SIGNUP_SIGNIN_MFa',
      token_endpoint: 'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/oauth2/v2.0/token?p=B2C_1A__SIGNUP_SIGNIN_MFa',
      issuer: 'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/v2.0/',
      end_session_endpoint:
        'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/oauth2/v2.0/logout?p=B2C_1A__SIGNUP_SIGNIN_MFa',
      jwks_uri: 'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/discovery/v2.0/keys?p=B2C_1A__SIGNUP_SIGNIN_MFa',
    },
  },
  azureUserProfileServiceConfig: {
    scopes: ['https://test.account.bbraun.com/auth/gpr/User.Read'],
    uri: 'https://api.myprofile.test.account.bbraun.com/api/me',
  },
  diagnosticEndpoint: 'https://1.1.1.1/cdn-cgi/trace',
};
