export const DropdownMenuSearchResults = 5;

export enum PHOTO_FRAME_SIZE {
  WIDTH = 700,
  HEIGHT = 500,
}

export enum PHOTO_DIALOG_ENTITY_TYPE {
  SET = 'SET',
  ITEM_TAPE_MARK = 'ITEM_TAPE_MARK',
  ITEM_STATE_REPAIR = 'ITEM_STATE_REPAIR',
  ITEM_STATE_REPLACEMENT = 'ITEM_STATE_REPLACEMENT',
  ITEM_STATE_SUPERFICIAL_ISSUE = 'ITEM_STATE_SUPERFICIAL_ISSUE',
  ITEM_STATE_GOOD_QUALITY = 'ITEM_STATE_GOOD_QUALITY',
  REPAIR_STAMP = 'REPAIR_STAMP',
}

export const TEMPORARY_PHOTO_ID_PREFIX = 'TEMPORARY_PHOTO_ID';
export const ENTITY_ID_PREFIX = 'ENTITY_ID';
export const PHOTO_PATH = 'PHOTO_PATH';
export const PHOTO_DIALOG_WIDTH = '1150px';

export const EMPTY_ITEM_CODE = '-';
