import { Injectable } from '@angular/core';
import { Table } from 'dexie';
import { EntityPhotoState, ItemStatePhoto } from '@shared/models/offline.model';
import { PhotoDataDexieService } from '@pwa/indexed-db/dexie-wrapper/photo-data-dexie.service';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { catchError, EMPTY, firstValueFrom, from, Observable, of } from 'rxjs';
import { ItemState } from '@shared/models/project-details.model';
import { ItemIdbService } from '@pwa/indexed-db/services/dynamic-data/item/item-idb.service';
import { areDatesEqual, createPhotoFileNameAndUpdateEntity } from '@shared/utilities/function/utils';
import { PhotoStoreService } from '@app/modules/project-details/core/services/photo-store.service';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpService } from '@shared/services/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ItemStatePhotoService {
  PHOTO_DOWNLOAD_URL_ITEM_STATE = 'photo/download/item-state';
  ITEM_STATE_PHOTO_UPLOAD_URL = environment.backendUrl + '/photo/upload/item-states';
  ITEM_STATE_PHOTO_OVERWRITE_URL = environment.backendUrl + '/photo/overwrite/item-state';

  private readonly itemStatePhotoStore: Table<ItemStatePhoto, number>;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private photoStore: PhotoStoreService,
    private itemIdbService: ItemIdbService,
    private photoDataDexieService: PhotoDataDexieService,
    readonly snacks: ResponseHandlerService
  ) {
    this.itemStatePhotoStore = this.photoDataDexieService.itemStatePhoto;
  }

  /**
   * Cloud photo functions
   */

  getCloudPhotoByPhotoPath(photoPath: string | undefined): Observable<Blob> {
    if (!photoPath) {
      console.error('Photo path must be present but is empty');
      return EMPTY;
    }
    let params: HttpParams = new HttpParams().set('photoPath', photoPath.toString());
    return this.httpService.request(this.PHOTO_DOWNLOAD_URL_ITEM_STATE).params(params).getBlob();
  }

  uploadCloudPhotosByPhotosInMemory(): Observable<Object | undefined> {
    const formData = new FormData();

    // Append each photo of ItemState to the FormData
    const photosItemStateInGoodQuality: File[] = this.photoStore.photoFilesItemStateGoodQuality;
    const photosItemStateRepair = this.photoStore.photoFilesItemStateRepair;
    const photosItemStateSuperficialIssue = this.photoStore.photoFilesItemStateSuperficialIssue;
    const photosItemStateReplacement = this.photoStore.photoFilesItemStateReplacement;

    // No photos to upload
    if (
      photosItemStateInGoodQuality.length === 0 &&
      photosItemStateSuperficialIssue.length === 0 &&
      photosItemStateRepair.length === 0 &&
      photosItemStateReplacement.length === 0
    ) {
      return of(undefined);
    }

    photosItemStateInGoodQuality.forEach((file) => {
      formData.append(`photo_item_state_in_good_quality_${file.name}`, file, file.name);
    });

    photosItemStateSuperficialIssue.forEach((file) => {
      formData.append(`photo_item_state_superficial_issue_${file.name}`, file, file.name);
    });

    photosItemStateRepair.forEach((file) => {
      formData.append(`photo_item_state_repair_${file.name}`, file, file.name);
    });

    photosItemStateReplacement.forEach((file) => {
      formData.append(`photo_item_state_replacement_${file.name}`, file, file.name);
    });

    return this.http.put(this.ITEM_STATE_PHOTO_UPLOAD_URL, formData);
  }

  // This method runs while data having no conflicts or after successful conflicts resolution
  async downloadUnSynCloudCreatedOrUpdatedPhotosByProjectId(projectId: number): Promise<void> {
    try {
      const allCloudItemStatesByHasPhotoTrue: ItemState[] = await this.itemIdbService.getItemStatesByProjectIdAndHasPhotoTrue(projectId);
      const allOfflineItemStatePhotos: ItemStatePhoto[] = await this.getAllOfflineItemStatePhotosByStatusNotCreated(projectId);
      const cloudItemStatesHavingUnSyncPhoto: ItemState[] = [];
      // Create a map for quick lookup
      const allOfflineItemStatePhotosMap = new Map(allOfflineItemStatePhotos.map((its: ItemStatePhoto) => [its.entityId, its]));

      // Find all cloud Item States which have unSync photo
      for (const cloudItemState of allCloudItemStatesByHasPhotoTrue) {
        // ItemStatePhoto entityID (ItemState ID) of type string means it is offline created
        if (!cloudItemState.photoDate || !cloudItemState.id) {
          console.error('Cloud Item State ID or photoDate must be present but is empty. Cloud ItemState: ', cloudItemState);
          continue;
        }

        const offlineMatchItemStatePhoto: ItemStatePhoto | undefined = allOfflineItemStatePhotosMap.get(cloudItemState.id);
        if (
          // If the current offline ItemStatePhoto was not offline updated
          offlineMatchItemStatePhoto?.status !== EntityPhotoState.UPDATED &&
          // and (the current cloud ItemState (and its photo) was cloud created
          (!offlineMatchItemStatePhoto ||
            // or the current cloud ItemState photo was cloud updated)
            !areDatesEqual(cloudItemState.photoDate, offlineMatchItemStatePhoto.photoDate))
        )
          cloudItemStatesHavingUnSyncPhoto.push(cloudItemState);
      }

      // Create all promises to be resolved
      const promises = cloudItemStatesHavingUnSyncPhoto.map(async (itemState) => {
        try {
          await this.downloadCloudPhotosAndPersistToOfflineDatabase(itemState);
        } catch (error) {
          console.error(`Error while populating photo for ItemState ${itemState.id}:`, error);
        }
      });

      // Download all photos parallel
      await Promise.all(promises);
    } catch (error) {
      console.error('Error in downloadUnSynCloudCreatedOrUpdatedPhotosByProjectId:', error);
    }
  }

  // This method runs while data having no conflicts or after successful conflicts resolution
  async uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId(projectId: number): Promise<void> {
    const allOfflineCreatedOrUpdatedItemStatePhotos: ItemStatePhoto[] = await this.getAllOfflineCreatedOrUpdatedItemStatePhotos(projectId);

    const uploadPromises = allOfflineCreatedOrUpdatedItemStatePhotos.map(async (isp: ItemStatePhoto) => {
      try {
        if (!isp.photo || !isp.photoDate || !isp.photoFileName) {
          const errorMessage = `Item State photo path must be present but is not. ItemStatePhoto: ${JSON.stringify(isp)}`;
          console.error(errorMessage);
          return Promise.reject(new Error(errorMessage));
        }

        await firstValueFrom(this.overwriteItemStatePhotoInCloud(isp));
        if (isp.status === EntityPhotoState.CREATED) {
          await firstValueFrom(this.photoDataDexieService.hardDelete(isp.entityId, this.itemStatePhotoStore));
        } else {
          isp.status = EntityPhotoState.PRISTINE;
          await firstValueFrom(this.photoDataDexieService.put(isp, this.itemStatePhotoStore));
        }
      } catch (error) {
        console.error(`Error in uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId()`, error);
      }
    });

    try {
      // Upload all photos parallel
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Error in uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId():', error);
    }
  }

  /**
   * Upload the offline photo and set photoDate with offline photoDate value
   * @param isp
   */
  private overwriteItemStatePhotoInCloud(isp: ItemStatePhoto): Observable<Object> {
    const formData = new FormData();
    const photoAsFile = new File([isp.photo], `${isp.photoFileName}`, { type: 'image/jpeg' });
    formData.append(`photo`, photoAsFile, photoAsFile.name);
    formData.append('photoDate', String(isp.photoDate.toISOString()));
    return this.http.put(this.ITEM_STATE_PHOTO_OVERWRITE_URL, formData);
  }

  private async downloadCloudPhotosAndPersistToOfflineDatabase(is: ItemState): Promise<void> {
    try {
      if (!is.photoPath) {
        console.error(`Item State photo path must be present but is not: ${is.photoPath}`);
        return;
      }

      const photo = await firstValueFrom(this.getCloudPhotoByPhotoPath(is.photoPath));

      if (!photo) {
        console.error(`Item State Cloud photo must be present but is not ${is.photoPath}`);
        return;
      }

      await firstValueFrom(this.postOfflineItemStatePhotoByItemStateAndPhotoBlob(is, photo));
    } catch (error) {
      console.error(`Failed to download photo for Item State ${is.id} (photo path: ${is.photoPath}) from Azure:`, error);
    }
  }

  /**
   * Offline photo functions
   */

  getOfflineItemStateStatePhotoByEntityId(itemStateId: number | string): Observable<ItemStatePhoto | undefined> {
    return from(this.itemStatePhotoStore.where(this.photoDataDexieService.ENTITY_PHOTO_PRIMARY_KEY).equals(itemStateId).first()).pipe(
      catchError(this.handleError('getByEntityId'))
    );
  }

  persistOfflineItemStatePhotosByItemStates(itemStates: ItemState[]): Observable<number | undefined> {
    let result: ItemStatePhoto[] = [];
    const photosItemStateInGoodQuality: File[] = this.photoStore.photoFilesItemStateGoodQuality;
    const photosItemStateRepair: File[] = this.photoStore.photoFilesItemStateRepair;
    const photosItemStateSuperficialIssue: File[] = this.photoStore.photoFilesItemStateSuperficialIssue;
    const photosItemStateReplacement: File[] = this.photoStore.photoFilesItemStateReplacement;

    const photosItemState = [
      ...photosItemStateInGoodQuality,
      ...photosItemStateRepair,
      ...photosItemStateSuperficialIssue,
      ...photosItemStateReplacement,
    ];

    if (photosItemState.length === 0) {
      return of(undefined);
    }

    photosItemState.forEach((file) => {
      const is = itemStates.find((is) => is.photoFileName === file.name);
      if (!is) return;
      const photoAsBlob = new Blob([file], { type: file.type });
      // Item State has a ID of type string is always offline created
      const itemStatePhoto = this.mapCloudItemStateToOfflineItemStatePhoto(is, photoAsBlob, typeof is.id === 'number');
      if (!itemStatePhoto) return;
      result.push(itemStatePhoto);
    });
    return this.photoDataDexieService.bulkPut(result, this.itemStatePhotoStore);
  }

  deleteOfflineCreatedOrUpdatedPhotosBySetId(setId: number): Observable<number> {
    return from(
      this.itemStatePhotoStore
        .where('setId')
        .equals(setId)
        .and((x: ItemStatePhoto) => x.status === EntityPhotoState.UPDATED || x.status === EntityPhotoState.CREATED)
        .delete()
    ).pipe(catchError(this.handleError('deleteOfflineCreatedOrUpdatedPhotosBySetId')));
  }

  private getAllOfflineCreatedOrUpdatedItemStatePhotos(projectId: number): Promise<ItemStatePhoto[]> {
    return this.itemStatePhotoStore
      .where(this.photoDataDexieService.ENTITY_PHOTO_FOREIGN_KEY)
      .equals(projectId)
      .and((its) => its.status === EntityPhotoState.CREATED || its.status === EntityPhotoState.UPDATED)
      .toArray();
  }

  private getAllOfflineItemStatePhotosByStatusNotCreated(projectId: number): Promise<ItemStatePhoto[]> {
    return this.itemStatePhotoStore
      .where(this.photoDataDexieService.ENTITY_PHOTO_FOREIGN_KEY)
      .equals(projectId)
      .and((its) => its.status !== EntityPhotoState.CREATED)
      .toArray();
  }

  private postOfflineItemStatePhotoByItemStateAndPhotoBlob(is: ItemState, photo: Blob): Observable<number> {
    if (!is.id || typeof is.id !== 'number' || !is.photoPath || !is.projectId) {
      console.error(`ItemState ${is.id} - Invalid ItemState: Missing required properties (id, photoPath, projectId).`);

      return EMPTY;
    }
    const itemStatePhoto: ItemStatePhoto = {
      entityId: is.id,
      setId: is.setId,
      status: EntityPhotoState.PRISTINE,
      photoFileName: createPhotoFileNameAndUpdateEntity(is),
      projectId: +is.projectId,
      photo: photo,
      photoDate: is.photoDate ? new Date(is.photoDate) : new Date(),
    };
    return this.photoDataDexieService.put(itemStatePhoto, this.itemStatePhotoStore);
  }

  private mapCloudItemStateToOfflineItemStatePhoto(is: ItemState, photo: Blob, isEditMode: boolean): ItemStatePhoto | undefined {
    if (!is.id || !is.projectId || !is.photoFileName) {
      console.error(`Invalid !is.id || !is.projectId || !is.photoFileName`);
      return undefined;
    }

    return {
      entityId: is.id,
      setId: is.setId,
      status: isEditMode ? EntityPhotoState.UPDATED : EntityPhotoState.CREATED,
      photoFileName: is.photoFileName,
      projectId: +is.projectId,
      photo: photo,
      photoDate: new Date(),
    };
  }

  private handleError(methodName: string): (error: any) => never {
    return (error: any) => {
      console.error(`Error in ItemStatePhotoService.${methodName}:`, error);
      throw error;
    };
  }
}
