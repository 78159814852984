import { Injectable } from '@angular/core';
import Dexie, { PromiseExtended, Table } from 'dexie';
import { Project } from '@shared/models/project-overviews.model';
import { InAReference, Item, Set, SetTitle, Speciality } from '@shared/models/project-details.model';
import { defaultIfEmpty, forkJoin, from, map, Observable } from 'rxjs';

import {
  INA_PROJECT_DATA_INDEXED_DB_NAME,
  INA_SYNC_DATA_INDEXED_DB_VERSION,
  ITEM_PRIMARY_KEY,
  PROJECT_PRIMARY_KEY,
  SET_PRIMARY_KEY,
  SET_TITLE_PRIMARY_KEY,
  SPECIALITY_PRIMARY_KEY,
} from '@pwa/constants/offline.constants';
import { ProjectReasonsDTO } from '@shared/models/static-data.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectDataDexieService extends Dexie {
  project!: Table<Project, number | string>;
  speciality!: Table<Speciality, number>;
  setTitle!: Table<SetTitle, number>;
  set!: Table<Set, number>;
  item!: Table<Item, number>;
  projectReasonsDTO!: Table<ProjectReasonsDTO, number>;
  inAReference!: Table<InAReference, number>;

  constructor() {
    super(INA_PROJECT_DATA_INDEXED_DB_NAME);
    const db = this;
    // Define tables and indexes
    db.version(INA_SYNC_DATA_INDEXED_DB_VERSION).stores({
      project: `&${PROJECT_PRIMARY_KEY}`,
      speciality: `&${SPECIALITY_PRIMARY_KEY}, name, projectId, [name+projectId]`,
      setTitle: `&${SET_TITLE_PRIMARY_KEY}, title, specialityId, projectId`,
      set: `&${SET_PRIMARY_KEY}, titleId, serialNumber, projectId`,
      item: `&${ITEM_PRIMARY_KEY}, parentOfItemId, projectId`,
      projectReasonsDTO: `++${ITEM_PRIMARY_KEY}, projectId`,
      inAReference: `++${ITEM_PRIMARY_KEY}, projectId, hasCustomProduct, competitorProductItemCode, competitorProductManufacturer, [competitorProductItemCode+competitorProductManufacturer]`,
    });
  }

  async initDb(): Promise<PromiseExtended<Dexie>> {
    // Dexie handles the creation and upgrading of stores automatically.
    // Additional initialization logic can go here if needed.
    // Since Dexie uses Promises, you can just return the Dexie database open promise.
    await this.open();
    return this;
  }

  // global logics
  public insertOrReplace<T>(entity: T, table: Table<T, number | string>): Observable<number | string> {
    return from(table.put(entity));
  }

  public bulkPost<T>(entity: Array<T>, table: Table<T, number | string>) {
    return from(table.bulkPut(entity));
  }

  public hardDelete<T>(key: number | string, table: Table<T, number | string>): Observable<void> {
    return from(table.delete(key));
  }

  public getLatestRecordId<T>(table: Table<Speciality | SetTitle | Set | Item, number>): Observable<number> {
    return from(
      table
        .orderBy('id')
        .reverse()
        .first()
        .then((latestRecord) => {
          if (latestRecord) {
            return latestRecord.id;
          } else {
            return 0;
          }
        })
    ).pipe(
      map((id) => id as number),
      defaultIfEmpty(0)
    );
  }

  public clearStoresByProjectId(projectId: number | string) {
    return forkJoin([
      from(this.project.where('id').equals(projectId).delete()),
      from(this.speciality.where('projectId').equals(projectId).delete()),
      from(this.setTitle.where('projectId').equals(projectId).delete()),
      from(this.set.where('projectId').equals(projectId).delete()),
      from(this.item.where('projectId').equals(projectId).delete()),
      from(this.inAReference.where('projectId').equals(projectId).delete()),
      from(this.projectReasonsDTO.where('projectId').equals(projectId).delete()),
    ]);
  }

  public specialityExists(name: string, projectId: number): Observable<boolean> {
    return from(
      this.speciality
        .where('name')
        .equals(name)
        .and((x: Speciality) => Number(x.projectId) === Number(projectId))
        .count()
        .then((count) => count !== 0)
    );
  }

  public setTitleExists(title: string, specialityId: number): Observable<boolean> {
    return from(
      this.setTitle
        .where('title')
        .equals(title)
        .and((x: SetTitle) => Number(x.specialityId) === Number(specialityId))
        .count()
        .then((count) => count !== 0)
    );
  }

  public setExists(serialNumber: string, setTitleId: number): Observable<boolean> {
    return from(
      this.set
        .filter((x: Set) => Number(x.titleId) === Number(setTitleId) && x.serialNumber.toLowerCase() === serialNumber.toLowerCase())
        .count()
        .then((count) => count !== 0)
    );
  }
}
