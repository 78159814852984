import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApplicationFacade } from '@app/store/facade/application.facade';
import * as AppActions from '@app/store/actions/application.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/reducers/application.reducer';
import { unsub } from '@shared/utilities/function/utils';

@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.sass'],
})
export class UnauthorisedComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  isOnline: boolean = true;

  constructor(
    private store: Store<AppState>,
    private applicationFacade: ApplicationFacade
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.applicationFacade.isOnline$.subscribe((isOnline) => {
        this.isOnline = isOnline;
      })
    );
  }

  toggleOnlineStatus(): void {
    this.store.dispatch(AppActions.toggleNetworkStatusAction());
  }

  ngOnDestroy(): void {
    unsub(this.subscriptions);
  }
}
