import { Injectable } from '@angular/core';
import { Observable, shareReplay, take } from 'rxjs';
import { ProjectDetailsService } from '@app/modules/project-details/core/services/project-details.service';
import { Project } from '@shared/models/project-overviews.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private projectDetailsService: ProjectDetailsService) {}

  getProject(projectId: number): Observable<Project> {
    return this.projectDetailsService.getProject(projectId).pipe(shareReplay(1), take(1));
  }
}
