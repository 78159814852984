import { Injectable, OnDestroy } from '@angular/core';
import { Speciality } from '@shared/models/project-details.model';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { Table } from 'dexie';
import { SyncDataDexieService } from '@pwa/indexed-db/dexie-wrapper/sync-data-dexie.service';
import { SYNC_CREATE_DATA_VERSION_VALUE } from '@pwa/constants/offline.constants';
import { catchError, EMPTY, from, map, Observable, of, shareReplay, Subscription, switchMap, take, tap, throwError } from 'rxjs';
import { translate, TranslocoService } from '@ngneat/transloco';
import { OfflineResponseErrorType } from '@pwa/enums/OfflineResponseErrorType';
import { ErrorResponse } from '@shared/models/error.model';

@Injectable({
  providedIn: 'root',
})
export class SpecialityIdbService implements OnDestroy {
  private specialityStore: Table<Speciality, number>;
  private SPECIALITY_STORE_NAME = 'speciality';
  private UN_SYNC_VERSION_VALUE = -1;

  translocoSub: Subscription;

  constructor(
    private appDataIndexedDbService: ProjectDataDexieService,
    private syncDataIndexedDbService: SyncDataDexieService,
    readonly responseHandlerService: ResponseHandlerService,
    private translocoService: TranslocoService
  ) {
    this.specialityStore = this.appDataIndexedDbService.table(this.SPECIALITY_STORE_NAME);

    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  getById(id: number | string): Observable<Speciality | undefined> {
    return from(this.specialityStore.where('id').equals(id).first()).pipe(
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-get-speciality-by-id-failed'));
        console.error(translate('snacks.offline-get-speciality-by-id-failed'), error);
        return throwError(() => error);
      })
    );
  }

  getAllByProjectId(projectId: number): Observable<Speciality[]> {
    return from(
      this.specialityStore
        .where('projectId')
        .equals(projectId)
        .and((x: any) => !x.deleted)
        .toArray()
    ).pipe(
      map((sets) => sets.sort((a, b) => a.name.localeCompare(b.name))),
      take(1),
      shareReplay(1),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-get-specialities-failed'));
        console.error(translate('snacks.offline-get-specialities-failed'), error);
        return throwError(() => error);
      })
    );
  }

  put(speciality: Speciality) {
    // Field "id, "deleted" are available
    const offlineSpeciality = {
      ...speciality,
      projectId: Number(speciality.projectId),
    } as Speciality;
    return this.appDataIndexedDbService.insertOrReplace(offlineSpeciality, this.specialityStore).pipe(
      switchMap((id: number | string) => this.getById(id)),
      tap((result) => {
        this.logDebug(result);
        if (result) {
          this.syncDataIndexedDbService.postSpeciality(result).subscribe({
            next: (response) => {
              this.logDebug('Put to ina_sync_put_data succeeds');
              this.logDebug(`${response}`);
            },
            error: (error) => {
              this.logDebug('Put to ina_sync_put_data fails');
              this.logDebug(error);
            },
          });
        }
      }),
      catchError((error) => {
        console.error(translate('snacks.offline-update-of-speciality-failed'), error);
        return throwError(() => error);
      })
    );
  }

  hardDeleteSpeciality(specialityId?: number | string): Observable<any> {
    if (specialityId) {
      return this.appDataIndexedDbService.hardDelete(specialityId, this.specialityStore).pipe(
        tap(() => {
          this.syncDataIndexedDbService.hardDeleteSpeciality(specialityId).subscribe({
            next: (response) => {
              this.logDebug('Delete to ina_sync_post_data succeeds');
              this.logDebug(`${response}`);
            },
            error: (error) => {
              this.logDebug('Delete to ina_sync_post_data fails');
              this.logDebug(error);
            },
          });
        }),
        catchError((error) => {
          this.responseHandlerService.handleError(translate('snacks.offline-deletion-of-speciality-failed'));
          console.error(translate('snacks.offline-deletion-of-speciality-failed'), error);
          // Rethrow the error for further handling
          return throwError(() => error);
        })
      );
    } else {
      // Immediately return an error if the speciality or its idbKey is not valid
      // TODO: translate this
      return throwError(() => new Error('Speciality is null or idbKey is undefined.'));
    }
  }

  softDeleteSpeciality(specialityId?: number | string) {
    if (specialityId) {
      return this.getById(specialityId).pipe(
        shareReplay(1),
        switchMap((speciality) => {
          const deletedSpeciality = {
            ...speciality,
            deleted: true,
          } as Speciality;
          return deletedSpeciality.version === this.UN_SYNC_VERSION_VALUE
            ? this.hardDeleteSpeciality(deletedSpeciality.id)
            : this.put(deletedSpeciality);
        })
      );
    } else {
      // TODO: translate this
      return throwError(() => 'Soft delete offline fails');
    }
  }

  checkIfSpecialityExistsByNamAndProjectId(name: string, projectId: number): Observable<boolean> {
    return this.appDataIndexedDbService.specialityExists(name, projectId).pipe(
      switchMap((specialityExists: boolean) => {
        if (specialityExists) {
          return throwError(() => {
            const error: ErrorResponse = {
              error: {
                type: OfflineResponseErrorType.DuplicatedEntity,
              },
            };
            return error;
          });
        }
        return of(false);
      })
    );
  }

  post(speciality: Speciality) {
    return this.appDataIndexedDbService.getLatestRecordId(this.specialityStore).pipe(
      switchMap((latestRecordId) => {
        // Field "id" is not available
        if (latestRecordId !== null && latestRecordId !== undefined) {
          const newId = latestRecordId + 1;
          const offlineSpeciality = {
            ...speciality,
            id: newId, // Should be unique
            projectId: Number(speciality.projectId),
            version: SYNC_CREATE_DATA_VERSION_VALUE,
            deleted: false,
            hasCloudParent: true,
          } as Speciality;

          return this.appDataIndexedDbService.insertOrReplace(offlineSpeciality, this.specialityStore).pipe(
            switchMap((id) => this.getById(id)),
            tap((result) => {
              this.logDebug(result);
              if (result) {
                this.syncDataIndexedDbService.postSpeciality(result).subscribe({
                  error: (error) => {
                    this.logDebug('Post to ina_sync_post_data fails');
                    this.logDebug(error);
                  },
                });
              }
            }),
            catchError((error) => {
              console.error(translate('snacks.offline-creation-of-speciality-failed'), error);
              return throwError(() => error);
            })
          );
        } else {
          // TODO: translate this
          return throwError(() => 'Cannot calculate Speciality Id');
        }
      })
    );
  }

  postSpecialities(specialities: Speciality[] | undefined): Observable<string | number> {
    if (!specialities) {
      return EMPTY;
    }
    // TODO: snack and translate this
    this.logDebug('Post offline Specialities succeeds');
    return this.appDataIndexedDbService.bulkPost(specialities, this.specialityStore);
  }

  /**
   * Synchronization services
   */
  putAndUpdateVersion(speciality: Speciality) {
    const offlineSpeciality = { ...speciality };

    return this.syncDataIndexedDbService.postSpeciality(offlineSpeciality).pipe(
      tap((response) => {
        this.logDebug('putAndUpdateVersion to syncDataIndexedDbService ran');
        this.logDebug(`${response}`);
      }),
      switchMap(() =>
        this.appDataIndexedDbService.insertOrReplace(offlineSpeciality, this.specialityStore).pipe(
          tap((result) => {
            this.logDebug('putAndUpdateVersion to appDataIndexedDbService ran');
            this.logDebug(result);
          })
        )
      ),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-update-of-speciality-failed'));
        console.error(translate('snacks.offline-update-of-speciality-failed'), error);
        // Rethrow the error for further handling
        return throwError(() => error);
      })
    );
  }

  // helper methods
  private logDebug(message: any) {
    // console.log('[speciality-idb.service.ts]: ' + message + '.');
  }
}
