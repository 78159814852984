export const ROUTES = {
  DATA_CONSOLIDATION: 'data-consolidation',
  HOME: '',
  PROJECT_OVERVIEWS: 'project-overviews',
  PROJECT_DETAILS: 'project-details',
  SEGMENTS: 'segments',
  STATS_AND_REPORTS: 'stats-and-reports',
  THEME: 'theme',
  UNAUTHORISED: 'unauthorised',
};
