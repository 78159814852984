import { Injectable } from '@angular/core';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { from, Observable, of } from 'rxjs';
import { MasterDataProduct } from '@shared/models/project-details.model';
import { Table } from 'dexie';
import { EmdnIdbService } from '@pwa/indexed-db/services/static-data/emdn-idb.service';
import { map } from 'rxjs/operators';
import { CategoryReasonsVM, ProjectReasonsDTO } from '@shared/models/static-data.model';
import { ReasonService } from '@app/modules/projects-overview/core/services/reason.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectCategoryReasonsIdbService {
  private readonly projectReasonsDTOStore: Table<ProjectReasonsDTO, number>;
  private PROJECT_REASON_STORE_NAME = 'projectReasonsDTO';

  constructor(
    private projectDataDexieService: ProjectDataDexieService,
    private reasonService: ReasonService,
    private emdnIdbService: EmdnIdbService,
    readonly responseHandlerService: ResponseHandlerService
  ) {
    this.projectReasonsDTOStore = this.projectDataDexieService.table(this.PROJECT_REASON_STORE_NAME);
  }

  postProjectReasons(projectReasons: ProjectReasonsDTO) {
    return projectReasons ? this.projectDataDexieService.bulkPost([projectReasons], this.projectReasonsDTOStore) : of([]);
  }

  getQualityCriteriaGroupByMasterDataProduct(masterDataProduct: MasterDataProduct) {
    const emdnCode = masterDataProduct.emdnCode;
    if (emdnCode) {
      return from(this.emdnIdbService.emdnStore.where('emdnCode').equals(emdnCode).first()).pipe(
        map((emdnEntity) => emdnEntity?.groupQualityCriteria)
      );
    } else {
      return of(undefined);
    }
  }

  getProjectReasonsDTOAndTransformToCategoryReasonVM(projectId: number | string): Observable<CategoryReasonsVM[]> {
    return from(this.projectDataDexieService.projectReasonsDTO.where('projectId').equals(+projectId).first()).pipe(
      map((projectReasonDTO) => (projectReasonDTO ? this.reasonService.transformToCategoryReasonVM(projectReasonDTO) : []))
    );
  }
}
