import { NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { ROOT_REDUCERS } from './store/reducers';
import { SNACKBAR_CONFIG } from '@bbraun/cortex/snackbar';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationEffects } from './store/effects/application.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpService } from '@shared/services/http/http.service';
import { CxMaterialConfigProviders } from '@bbraun/cortex';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  ProtocolMode,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { environment } from '@environments/environment';
import { OfflineInterceptor } from '@shared/interceptors/app-offline-interceptor';
import { CxHeartBeatModule } from '@bbraun/cortex/carousel';
import { TranslocoRootModule } from './transloco-root.module';
import { customSnackbarConfig } from '@shared/constants/snackbar.config.constant';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      redirectUri: environment.msalConfig.auth.redirectUri,
      authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
      authorityMetadata: JSON.stringify({
        authorization_endpoint: environment.b2cPolicies.authorityMetadata.authorization_endpoint,
        token_endpoint: environment.b2cPolicies.authorityMetadata.token_endpoint,
        issuer: environment.b2cPolicies.authorityMetadata.issuer,
        end_session_endpoint: environment.b2cPolicies.authorityMetadata.end_session_endpoint,
        jwks_uri: environment.b2cPolicies.authorityMetadata.jwks_uri,
      }),
      postLogoutRedirectUri: '/',
      knownAuthorities: [environment.b2cPolicies.authorityDomain],
      navigateToLoginRequestUrl: true,
      protocolMode: ProtocolMode.OIDC,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);
  protectedResourceMap.set(environment.azureUserProfileServiceConfig.uri, environment.azureUserProfileServiceConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
    },
    loginFailedRoute: '/login-failed',
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    MsalModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot(ROOT_REDUCERS),
    EffectsModule.forRoot([ApplicationEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, connectInZone: true }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CxHeartBeatModule,
    TranslocoRootModule,
  ],
  providers: [
    HttpService,
    {
      provide: SNACKBAR_CONFIG,
      useValue: customSnackbarConfig,
    },
    CxMaterialConfigProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer
  ) {
    this.setupCustomIcons();
  }

  private setupCustomIcons() {
    const icons = [
      {
        name: 'welcome-illustration',
        url: 'welcome_Illustration.svg',
      },
      {
        name: 'no_results_Illustration',
        url: 'no_results_Illustration.svg',
      },
      {
        name: 'app-logo-white',
        url: 'bbraun-app-logo.svg',
      },
      {
        name: 'warning-icon',
        url: 'warning-icon.svg',
      },
      {
        name: 'question-mark-icon',
        url: 'question-mark.svg',
      },
      {
        name: 'cloud-sync-icon',
        url: 'cloud-sync.svg',
      },
      {
        name: 'merge-icon',
        url: 'merge.svg',
      },
    ].map((icon) => ({ name: icon.name, url: 'assets/images/' + icon.url }));

    for (const icon of icons) {
      this.matIconRegistry.addSvgIcon(icon.name, this.sanitizer.bypassSecurityTrustResourceUrl(icon.url));
    }
  }
}
