import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { ApplicationFacade } from '@app/store/facade/application.facade';
import { combineLatest, filter, Subject, takeUntil } from 'rxjs';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { SyncDataDexieService } from '@pwa/indexed-db/dexie-wrapper/sync-data-dexie.service';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { environment } from '@environments/environment';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { OfflineDataService } from '@pwa/services/offline-data.service';
import { StaticDataDexieService } from '@pwa/indexed-db/dexie-wrapper/static-data-dexie.service';
import { IdTokenClaimsWithPolicyId } from '@shared/interfaces/id-token-claims-with-policy-id.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DownloadDataService } from '@pwa/services/download/download-data.service';
import { PhotoDataDexieService } from '@pwa/indexed-db/dexie-wrapper/photo-data-dexie.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  providers: [ApplicationFacade],
  animations: [
    // The fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [
      // The 'in' style determines the 'resting' state of the element when it is visible
      state('in', style({ opacity: 1 })),

      // Fade in when created. this could also be written as transition('void => *')
      transition(':enter', [style({ opacity: 0 }), animate(400)]),

      // Fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(400, style({ opacity: 0 }))),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  isIframe: boolean = false;
  loginDisplay: boolean = false;
  // This will set how wide the Cortex heart beat loader is
  loadingIndicatorWidth: string = '25%';

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly appDataIndexedDbService: ProjectDataDexieService,
    private readonly staticDataIndexedDbService: StaticDataDexieService,
    private readonly syncDataIndexedDbService: SyncDataDexieService,
    private readonly photoDataDexieService: PhotoDataDexieService,
    private readonly offlineDataService: OfflineDataService,
    private readonly downloadDataService: DownloadDataService,
    private readonly snackbarService: CxSnackbarService,
    private cdr: ChangeDetectorRef,
    readonly applicationFacade: ApplicationFacade
  ) {
    // DO NOT DELETE
    // Needed to set ViewContainerRef globally to prevent
    // from setting it in every component/service/effect/*
    // where CXSnackbarService is used
    this.snackbarService.setViewContainerRef(this.viewContainerRef);
    this.applicationFacade.checkNetworkStatus();
    this.appDataIndexedDbService.initDb();
    this.syncDataIndexedDbService.initDb();
    this.staticDataIndexedDbService.initDb();
    this.photoDataDexieService.initDb();
  }

  ngOnInit(): void {
    // Combining both `isOnline$` and `areMasterDataDownloaded()`, updating whenever either changes, thus ensuring
    // that as soon as the App is online and the MasterData is not downloaded - the download actions are triggered.
    combineLatest([this.applicationFacade.isOnline$, this.offlineDataService.areMasterDataDownloaded()])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([isOnline, isDownloaded]: [boolean, boolean]) => {
        if (isOnline && !isDownloaded) {
          this.downloadDataService.downloadAndPersistAllMasterDataProducts();
          this.downloadDataService.downloadAndPersistAllReferences();
          this.offlineDataService.downloadAndPersistStaticDataEMDNEntries();
        }
      });

    // Subscribe to the loading state observable from the facade and update the local loading property
    this.applicationFacade.isAppLoading$.pipe(takeUntil(this.onDestroy$)).subscribe((loading) => {
      this.loading = loading;

      // Prevents the NG0100 error when `loading` has a different value after Angular has completed the change detection
      this.cdr.detectChanges();
    });

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    /**
     * MASSIVE TODO: Make this the only MSAL subscription, otherwise some paths do not get the User.
     *              - create a User interface;
     *              - create a User service with a User Subject in it;
     *              - check for the subject in the app-header.
     * https://stackoverflow.com/questions/61460789/broadcast-service-not-working-with-msal-in-angular
     */
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED))
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idToken: IdTokenClaimsWithPolicyId = payload.idTokenClaims;

        if (idToken.acr === environment.b2cPolicies.names.signUpSignIn || idToken.tfp === environment.b2cPolicies.names.signUpSignIn) {
          this.authService.instance.setActiveAccount(payload.account);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this.onDestroy$)
      )
      .subscribe((result: EventMessage) => {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
}
