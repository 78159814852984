import { Injectable, OnDestroy } from '@angular/core';
import { catchError, EMPTY, forkJoin, from, Observable, of, Subscription, switchMap, tap, throwError } from 'rxjs';
import { HttpService } from '@shared/services/http/http.service';
import { Project } from '@shared/models/project-overviews.model';
import { ProjectIdbService } from '@app/modules/pwa/indexed-db/services/dynamic-data/project/project-idb.service';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { SpecialityIdbService } from '@app/modules/pwa/indexed-db/services/dynamic-data/speciality/speciality-idb.service';
import { Item, Set, SetTitle, Speciality } from '@shared/models/project-details.model';
import { SetTitleIdbService } from '@app/modules/pwa/indexed-db/services/dynamic-data/set-title/set-title-idb.service';
import { SyncDataDexieService } from '@pwa/indexed-db/dexie-wrapper/sync-data-dexie.service';
import { OfflineStaticDataDTO, SetPhoto } from '@shared/models/offline.model';
import { SetIdbService } from '@pwa/indexed-db/services/dynamic-data/set/set-idb.service';
import { ItemIdbService } from '@pwa/indexed-db/services/dynamic-data/item/item-idb.service';
import { StaticDataMinorDomainsIdbService } from '@pwa/indexed-db/services/static-data/static-data-minor-domains-idb.service';
import { MasterDataProductIdbService } from '@pwa/indexed-db/services/static-data/master-data-product-idb.service';
import * as ApplicationActions from '@app/store/actions/application.actions';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/store/reducers';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { DownloadDataService } from '@pwa/services/download/download-data.service';
import { EmdnIdbService } from '@pwa/indexed-db/services/static-data/emdn-idb.service';
import { SetService } from '@app/modules/project-details/core/services/set.service';
import { translate, TranslocoService } from '@ngneat/transloco';
import { SetPhotoIdbService } from '@shared/services/photo/set-photo-idb.service';
import { ItemPhotoService } from '@shared/services/photo/item-photo.service';
import { Page } from '@shared/models/paginaton.model';
import { ItemStatePhotoService } from '@shared/services/photo/item-state-photo.service';
import { RepairStampPhotoService } from '@shared/services/photo/repair-stamp-photo.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineDataService implements OnDestroy {
  translocoSub: Subscription;

  constructor(
    readonly httpService: HttpService,
    readonly syncDataIndexedDbService: SyncDataDexieService,
    readonly appDataIndexedDbService: ProjectDataDexieService,
    readonly setPhotoIdbService: SetPhotoIdbService,
    readonly itemPhotoService: ItemPhotoService,
    readonly itemStatePhotoService: ItemStatePhotoService,
    readonly repairStampPhotoService: RepairStampPhotoService,
    readonly staticDataIdbService: StaticDataMinorDomainsIdbService,
    readonly projectIdbService: ProjectIdbService,
    readonly specialityIdbService: SpecialityIdbService,
    readonly setTitleIdbService: SetTitleIdbService,
    readonly setIdbService: SetIdbService,
    readonly setService: SetService,
    readonly itemIdbService: ItemIdbService,
    readonly masterDataProductIdbService: MasterDataProductIdbService,
    readonly downloadDataService: DownloadDataService,
    readonly emdnService: EmdnIdbService,
    readonly responseHandlerService: ResponseHandlerService,
    private readonly store: Store<fromRoot.State>,
    private translocoService: TranslocoService
  ) {
    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  simulateGoingOffline() {
    this.store.dispatch(ApplicationActions.goOfflineAction());
  }

  /**
   * Project data
   */

  getAndPersistAllProjectData(projectId: number): Observable<[any, any, any]> {
    return forkJoin([
      this.getAndPersistSpecialitiesSetTitles(projectId),
      this.getAndPersistSets(projectId),
      this.getAndPersistDataOnItemLevel(projectId),
    ]);
  }

  /**
   * This method performs the following operations sequentially:
   *
   * Downloads Sets by projectId from BE.
   * Transforms and persists these sets into an IndexedDB store.
   * Retrieves existing photo IDs associated with the sets from the IndexedDB store. (Set photo ID = Set ID)
   * Downloads and persists photos for the sets not already present in the IndexedDB store.
   * @param projectId
   */
  getAndPersistSets(projectId: number): Observable<any> {
    return this.downloadDataService.getSetsByProjectId(projectId).pipe(
      // Step 1: Fetch Sets associated with the given project ID
      switchMap((sets: Set[]) => {
        let transformedSets = sets.map((set) => this.mapAndTransformSet(set));

        // Step 2: Persist the transformed Sets into IndexedDB
        return this.setIdbService.postSets(transformedSets).pipe(
          switchMap(() => {
            // Step 3: Retrieve existing photo IDs from IndexedDB
            return from(this.setPhotoIdbService.getAllByProjectId(projectId)).pipe(
              switchMap((setPhotos: SetPhoto[]) => {
                // Step 4: Download and persist photos for sets not already stored locally
                const setsByHasPhotoTrue = sets.filter((set) => set.hasPhoto);
                return from(
                  this.setPhotoIdbService.persistNewCloudAndOfflineCreatedOrUpdatedSetPhotos(setsByHasPhotoTrue, setPhotos, projectId)
                );
              })
            );
          })
        );
      }),
      catchError((error) => {
        console.error('Error in getAndPersistSets()', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * This method handles download of normal data and download/upload of photo data on Item level
   * and runs while data having no conflicts or after successful conflicts resolution
   * @param projectId
   */
  getAndPersistDataOnItemLevel(projectId: number) {
    // Step 1: Upload offline created and updated photos to cloud database on Item level
    return from(
      forkJoin([
        this.itemPhotoService.uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId(projectId),
        this.itemStatePhotoService.uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId(projectId),
        this.repairStampPhotoService.uploadUnSyncOfflineCreatedOrUpdatedPhotosByProjectId(projectId),
      ])
    ).pipe(
      switchMap(() =>
        // Step 2: Download normal data of Item (including Item States and Repair Stamps) from cloud
        this.getAndPersistNestedItems(projectId).pipe(
          switchMap(() =>
            // Step 3: Download Item, Item States and Repair Stamps created and updated cloud photos to offline database (after Step 1, order matters in this case to ensure latest version of data)
            forkJoin([
              this.itemPhotoService.downloadUnSyncCloudCreatedOrUpdatedPhotosByProjectId(projectId),
              this.itemStatePhotoService.downloadUnSynCloudCreatedOrUpdatedPhotosByProjectId(projectId),
              this.repairStampPhotoService.downloadUnSynCloudCreatedOrUpdatedPhotosByProjectId(projectId),
            ])
          )
        )
      )
    );
  }

  getAndPersistNestedItems(projectId: number): Observable<[string | number, ...Page<Item>[]] | undefined> {
    return this.downloadDataService.getNestedItemsByProjectId(projectId).pipe(
      switchMap((defaultPageItem: Page<Item>) => {
        const totalPages = defaultPageItem.totalPages;

        // There are no Items
        if (!totalPages) {
          return of(undefined);
        }

        // Create an Observable stream for each page request (starting from pageIndex 1)
        const itemsPersistence$: Observable<Page<Item>>[] = [];
        // Step 1: Fetch paginated Items by project ID for the next pages starting from 1 as page 0 is already fetched at this point
        for (let pageIndex = 1; pageIndex < totalPages; pageIndex++) {
          itemsPersistence$.push(
            this.downloadDataService.getNestedItemsByProjectId(projectId, pageIndex).pipe(
              tap((page: Page<Item>) => {
                const itemsByPage = page.content.map((item) => this.mapAndTransformItem(item, projectId));
                // Step 2: Persist the transformed nested Items of the current page into IndexedDB
                return this.itemIdbService.postItems(itemsByPage).pipe(
                  catchError((error) => {
                    console.error('Error persisting items for page', pageIndex, error);
                    return EMPTY;
                  })
                );
              })
            )
          );
        }

        // Handle the first page explicitly:
        const firstPagePersistence$ = this.itemIdbService
          .postItems(defaultPageItem.content.map((item) => this.mapAndTransformItem(item, projectId)))
          .pipe(
            catchError((error) => {
              console.error('Error persisting items for first page', 0, error);
              return EMPTY;
            })
          );

        return forkJoin([firstPagePersistence$, ...itemsPersistence$]);
      })
    );
  }

  getAndPersistSpecialitiesSetTitles(projectId: number): Observable<any> {
    return this.downloadDataService.getProjectData(projectId).pipe(
      switchMap((project: Project) => {
        return this.postProjectSpecialitySetTitleToIndexedDB(project);
      })
    );
  }

  private postProjectSpecialitySetTitleToIndexedDB(nestedProject: Project) {
    // @ts-ignore
    nestedProject = nestedProject[0];
    const project = this.extractProject(nestedProject);
    const specialities = this.extractSpecialities(nestedProject);

    const allSetTitlesOfEachSpeciality: SetTitle[] = [];

    if (!nestedProject.specialities) {
      return this.projectIdbService.postProject(project);
    }

    for (const speciality of nestedProject.specialities) {
      let currentSetTitles = this.extractSetTitles(speciality);
      if (currentSetTitles) {
        allSetTitlesOfEachSpeciality.push(...currentSetTitles);
      }
    }

    const project$ = this.projectIdbService.postProject(project);
    const specialities$ = this.specialityIdbService.postSpecialities(specialities);
    const setTitles$ = this.setTitleIdbService.postSetTitles(allSetTitlesOfEachSpeciality);

    return forkJoin([project$, specialities$, setTitles$]);
  }

  /**
   * Static data
   */

  areMasterDataDownloaded() {
    return this.masterDataProductIdbService.isIndexedDBTableEmpty();
  }

  downloadAndPersistGeneralStaticData(): Observable<any> {
    return this.downloadDataService.getGeneralStaticData().pipe(
      switchMap((offlineStaticDataDTO: OfflineStaticDataDTO) => {
        return this.postGeneralStaticData(offlineStaticDataDTO);
      }),
      catchError((error) => {
        console.error('Error downloading and persisting static data', error);
        return of(null); // handle the error by returning an observable that completes
      })
    );
  }

  private postGeneralStaticData(offlineStaticDataDTO: OfflineStaticDataDTO) {
    const itemStateCategories = offlineStaticDataDTO.itemStateCategory;
    const categories = offlineStaticDataDTO.categories;

    const itemStateCategories$ = this.staticDataIdbService.postItemStateCategory(itemStateCategories);
    const categories$ = this.staticDataIdbService.postCategories(categories);
    const defaultCategoryReasonsVM$ = this.downloadDataService.getDefaultProjectCategoryReasonsVM().pipe(
      switchMap((projectCategoryReasonsVM) => this.staticDataIdbService.postDefaultProjectCategoryReasons(projectCategoryReasonsVM)),
      catchError((error) => {
        console.error('loadUpdateData error:', error);
        return throwError(error);
      })
    );

    return forkJoin([itemStateCategories$, categories$, defaultCategoryReasonsVM$]);
  }

  downloadAndPersistStaticDataEMDNEntries() {
    return this.downloadDataService.getStaticDataEmdnEntries().subscribe((masterData) => {
      this.emdnService.postEmdnOffline(masterData).subscribe({
        next: () => {
          // No need for a separate snack for these entries, there is one major snack when all data gets downloaded
          console.log(translate('snacks.static-emdn-reference-data-has-been-downloaded-for-offline-use'));
        },
        error: (error) => {
          this.responseHandlerService.handleError(translate('snacks.downloading-offline-master-data-failed'));
          console.error(translate('snacks.downloading-offline-master-data-failed'), error);
        },
      });
    });
  }

  isSyncDbEmpty(projectId: number | string) {
    return this.syncDataIndexedDbService.isSyncDbEmpty(projectId);
  }

  areProjectDataAlreadyDownloaded(projectId: number) {
    return this.projectIdbService.getByProjectId(projectId).pipe(
      switchMap((project) => {
        if (project) {
          // Project exists, return an error observable
          return of(true);
        } else {
          // Project does not exist, return a success observable
          return of(false);
        }
      }),
      catchError((error) => {
        // Handle any errors that occurred during the getByProjectId call
        this.responseHandlerService.handleError(translate('snacks.offline-get-project-by-id-failed'));
        console.error(translate('snacks.offline-get-project-by-id-failed'), error);
        return throwError(() => translate('snacks.offline-get-project-by-id-failed'));
      })
    );
  }

  // [IVAN] Note: from this point on nothing is translated, as those are probably debug logs

  // helper methods
  private extractProject(nestedProject: Project): Project {
    const { specialities, ...project } = nestedProject;
    return project;
  }

  private extractSpecialities(nestedProject: Project): Array<Speciality> | undefined {
    const { specialities, ...project } = nestedProject;
    if (!specialities) {
      return undefined;
    }
    return specialities.map((speciality) => this.extractSpeciality(speciality));
  }

  private extractSpeciality(nestedSpeciality: Speciality): Speciality {
    const { titles, ...speciality } = nestedSpeciality;
    speciality.hasCloudParent = true;
    return speciality;
  }

  private extractSetTitles(nestedSpeciality: Speciality): Array<SetTitle> | undefined {
    const { titles, ...speciality } = nestedSpeciality;
    if (!titles) {
      return undefined;
    }
    return titles.map((setTitle) => this.extractSetTitle(setTitle));
  }

  private extractSetTitle(nestedSetTitle: SetTitle): SetTitle {
    const { sets, ...setTitle } = nestedSetTitle;
    setTitle.hasCloudParent = true;
    return setTitle;
  }

  private mapAndTransformSet(set: Set): Set {
    set.hasCloudParent = true;
    return set;
  }

  private mapAndTransformItem(nestedItem: Item, projectId: number): Item {
    nestedItem.projectId = projectId;
    nestedItem.hasCloudParent = true;
    return nestedItem;
  }
}
