import { Injectable } from '@angular/core';
import { Table } from 'dexie';
import { Set } from '@shared/models/project-details.model';
import { EntityPhotoState, SetPhoto } from '@shared/models/offline.model';
import { PhotoDataDexieService } from '@pwa/indexed-db/dexie-wrapper/photo-data-dexie.service';
import { catchError, EMPTY, firstValueFrom, forkJoin, from, map, mergeMap, Observable, of, switchMap, throwError } from 'rxjs';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { areDatesEqual, createPhotoFileNameAndUpdateEntity } from '@shared/utilities/function/utils';
import { CloudPhotoService } from '@app/modules/project-details/core/services/cloud-photo.service';

@Injectable({
  providedIn: 'root',
})
export class SetPhotoIdbService {
  private readonly setPhotoStore: Table<SetPhoto, number>;

  constructor(
    private photoDataDexieService: PhotoDataDexieService,
    private cloudPhotoService: CloudPhotoService,
    readonly snacks: ResponseHandlerService
  ) {
    this.setPhotoStore = this.photoDataDexieService.setPhoto;
  }

  getByEntityId(setId: number): Observable<SetPhoto | undefined> {
    return from(this.setPhotoStore.where(this.photoDataDexieService.ENTITY_PHOTO_PRIMARY_KEY).equals(setId).first());
  }

  getAndSetStatusToPristineByEntityId(setId: number): Observable<SetPhoto> {
    return from(this.setPhotoStore.where(this.photoDataDexieService.ENTITY_PHOTO_PRIMARY_KEY).equals(setId).first()).pipe(
      switchMap((setPhoto: SetPhoto | undefined) => {
        if (!setPhoto) return EMPTY;

        // Persist the updated set photos back into IndexedDB
        setPhoto.status = EntityPhotoState.PRISTINE;
        return this.photoDataDexieService.put(setPhoto, this.setPhotoStore).pipe(map(() => setPhoto));
      })
    );
  }

  /**
   * Retrieves all photo entity IDs for a given project ID from the IndexedDB store.
   *
   * @param {number} projectId - The ID of the project to filter the photo entity IDs.
   * @returns {Observable<number[]>} - An observable containing the list of photo entity IDs.
   */
  getAllByProjectId(projectId: number): Observable<SetPhoto[]> {
    return from(this.setPhotoStore.where('projectId').equals(projectId).toArray()).pipe(
      catchError((error) => {
        console.error('Error in getAllSetPhotoEntityIdsByProjectId()', error);
        return throwError(() => error);
      })
    );
  }

  bulkPut(setPhotos: SetPhoto[]): Observable<number> {
    return this.photoDataDexieService.bulkPut(setPhotos, this.setPhotoStore);
  }

  put(set: Set, photo: Blob, photoFileName: string, projectId: number, isInEditMode: boolean): Observable<number> {
    if (!set.id) {
      console.error('Set ID is invalid');
      return EMPTY;
    }
    const setPhoto: SetPhoto = {
      entityId: set.id,
      photo: photo,
      photoDate: new Date(),
      photoFileName: photoFileName,
      projectId: projectId,
      status: isInEditMode ? EntityPhotoState.UPDATED : EntityPhotoState.CREATED,
    };
    return this.photoDataDexieService.put(setPhoto, this.setPhotoStore);
  }

  delete(entityId: number): Observable<void> {
    return this.photoDataDexieService.hardDelete(entityId, this.setPhotoStore).pipe(
      catchError((error) => {
        console.error(`Error setPhotoIdbService.deleteSetPhoto for offline Set ${entityId}`, error);
        throw () => error;
      })
    );
  }

  /**
   * Downloads and persists Set having NO CONFLICTS. This means Set photos
   * EITHER created or updated only in offline
   * OR created or updated only in cloud
   * @param {Set[]} cloudSetsByHasPhotoTrue - The list of sets to process.
   * @param {number[]} offlineSetPhotos - List of Set photo already persisted in IndexedDB.
   * @param {number} projectId - The project ID associated with these sets.
   * @returns {Promise<void>} - A promise that resolves when all photos are downloaded and persisted.
   */
  async persistNewCloudAndOfflineCreatedOrUpdatedSetPhotos(
    cloudSetsByHasPhotoTrue: Set[],
    offlineSetPhotos: SetPhoto[],
    projectId: number
  ): Promise<void> {
    /*
    Persist Set photos created only in cloud
    There is no associated SetPhoto
     */
    const cloudCreatedSetPhotos = this.findCloudCreatedSetPhotos(cloudSetsByHasPhotoTrue, offlineSetPhotos);
    if (cloudCreatedSetPhotos.length > 0) {
      // Wait for the IndexedDB persistence to finish before moving to the next step
      await this.persistToIndexedDB(cloudCreatedSetPhotos, projectId);
    }

    /*
    Persist Set photos updated only in cloud
    SetPhoto status === 'PRISTINE'
    */
    const cloudUpdatedSetPhotos = this.findCloudUpdatedSetPhotos(cloudSetsByHasPhotoTrue, offlineSetPhotos);
    if (cloudUpdatedSetPhotos.length > 0) {
      // Wait for the IndexedDB persistence to finish before moving to the next step
      await this.persistToIndexedDB(cloudUpdatedSetPhotos, projectId);
    }

    /*
    Persist Set photos created or updated only in offline
    SetPhoto status === 'CREATED' or status === 'UPDATED'
     */
    // This runs only after the above logic finishes
    this.uploadCreatedOrUpdatedSetPhotosByProjectId(projectId);
  }

  async persistToIndexedDB(sets: Set[], projectId: number): Promise<void> {
    try {
      const setPhotos: SetPhoto[] = [];
      for (const set of sets) {
        if (!set.photoDate || !set.id) {
          console.error(`Set photoDate or id must be present but is not. Id: ${set.id}, photoDate: ${set.photoDate}`);
          continue;
        }

        const cloudPhoto = await firstValueFrom(this.cloudPhotoService.downloadPhotoSet(set.photoPath));
        if (!cloudPhoto) {
          console.error(`Cloud data Set photo for Set ${set.id} is not found`);
          return;
        }

        const { id, photoPath, photoDate } = set;

        if (!photoPath) {
          console.error(`photoPath of Set ${set.id} must be present but is empty`);
          continue;
        }

        const setPhoto: SetPhoto = {
          entityId: id,
          photo: cloudPhoto,
          photoDate: new Date(photoDate),
          projectId: projectId,
          photoFileName: createPhotoFileNameAndUpdateEntity(set),
          status: EntityPhotoState.PRISTINE,
        };
        setPhotos.push(setPhoto);
      }
      await firstValueFrom(this.bulkPut(setPhotos));
    } catch (error) {
      console.error(`Failed to getAndPersistSetPhotos()`, error);
    }
  }

  uploadCreatedOrUpdatedSetPhotosByProjectId(projectId: number): void {
    this.loadSetPhotosCreatedOrUpdatedOffline(projectId)
      .pipe(
        mergeMap((setPhotos: SetPhoto[]) => {
          if (setPhotos.length === 0) {
            return EMPTY;
          }

          const uploadObservables = this.buildUploadSetPhotos$(setPhotos);
          if (uploadObservables.length === 0) {
            return EMPTY;
          }

          return forkJoin(uploadObservables);
        }),
        catchError((error) => {
          console.error('Error of cloudPhotoService.uploadOfflinePhotoSet:', error);
          return of(null);
        })
      )
      .subscribe({
        error: (error) => {
          console.error('Error of uploadUpdateDataSetPhotoByProjectId:', error);
        },
      });
  }

  private findCloudCreatedSetPhotos(cloudSetsByHasPhotoTrue: Set[], offlineSetPhotos: SetPhoto[]): Set[] {
    const cloudCreatedSets: Set[] = [];
    const photosMap = new Map(offlineSetPhotos.map((photo) => [photo.entityId, photo]));

    for (const cloudSet of cloudSetsByHasPhotoTrue) {
      if (!cloudSet.photoDate || !cloudSet.id) {
        console.error('A cloud Set photoDate and ID must be present but is not');
        break;
      }
      const matchedPhoto = photosMap.get(cloudSet.id);
      if (!matchedPhoto) cloudCreatedSets.push(cloudSet);
    }

    return cloudCreatedSets;
  }

  private findCloudUpdatedSetPhotos(cloudSetsByHasPhotoTrue: Set[], offlineSetPhotos: SetPhoto[]): Set[] {
    const offlinePristineSetPhotos = offlineSetPhotos.filter((itemPhoto) => itemPhoto.status === EntityPhotoState.PRISTINE);
    const cloudSetsHavingUpdatedPhotos: Set[] = [];
    const photosMap = new Map(offlinePristineSetPhotos.map((photo) => [photo.entityId, photo]));

    for (const cloudSet of cloudSetsByHasPhotoTrue) {
      if (!cloudSet.photoDate || !cloudSet.id) {
        console.error('A cloud Set photoDate and ID must be present but is not');
        break;
      }
      const matchedPhoto = photosMap.get(cloudSet.id);
      if (matchedPhoto && !areDatesEqual(cloudSet.photoDate, matchedPhoto.photoDate)) cloudSetsHavingUpdatedPhotos.push(cloudSet);
    }

    return cloudSetsHavingUpdatedPhotos;
  }

  private loadSetPhotosCreatedOrUpdatedOffline(projectId: number): Observable<SetPhoto[]> {
    return from(
      this.photoDataDexieService.setPhoto
        .where('projectId')
        .equals(projectId)
        .and((setPhoto: SetPhoto) => setPhoto.status === EntityPhotoState.CREATED || setPhoto.status === EntityPhotoState.UPDATED)
        .toArray()
    ).pipe(
      switchMap((setPhotos: SetPhoto[]) => {
        // Update the status of each photo to PRISTINE
        const updatedSetPhotos = setPhotos.map((setPhoto) => ({
          ...setPhoto,
          status: EntityPhotoState.PRISTINE,
        }));
        // Persist the updated set photos back into IndexedDB
        return this.bulkPut(updatedSetPhotos).pipe(
          // Emit the updated set photos after saving
          map(() => setPhotos)
        );
      })
    );
  }

  private buildUploadSetPhotos$(sets: SetPhoto[]): Observable<Object>[] {
    const uploadObservables = [];

    for (const set of sets) {
      const uploadObservable = this.cloudPhotoService.uploadOfflineSetPhoto(set);
      uploadObservables.push(uploadObservable);
    }

    return uploadObservables;
  }
}
