import { Injectable } from '@angular/core';
import Dexie, { PromiseExtended, Table } from 'dexie';
import { SetPhoto, ItemPhoto, ItemStatePhoto, RepairStampPhoto } from '@shared/models/offline.model';
import { INA_PHOTO_DATA_INDEXED_DB_NAME, INA_SYNC_DATA_INDEXED_DB_VERSION } from '@pwa/constants/offline.constants';
import { catchError, from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhotoDataDexieService extends Dexie {
  readonly ENTITY_PHOTO_PRIMARY_KEY = 'entityId';
  readonly ENTITY_PHOTO_FOREIGN_KEY = 'projectId';

  // Stores
  setPhoto!: Table<SetPhoto, number>;
  itemPhoto!: Table<ItemPhoto, number>;
  itemStatePhoto!: Table<ItemStatePhoto, number>;
  repairStampPhoto!: Table<RepairStampPhoto, number>;

  constructor() {
    super(INA_PHOTO_DATA_INDEXED_DB_NAME);
    const db = this;
    db.version(INA_SYNC_DATA_INDEXED_DB_VERSION).stores({
      setPhoto: `&${this.ENTITY_PHOTO_PRIMARY_KEY}, ${this.ENTITY_PHOTO_FOREIGN_KEY}`,
      itemPhoto: `&${this.ENTITY_PHOTO_PRIMARY_KEY}, ${this.ENTITY_PHOTO_FOREIGN_KEY}, setId`,
      itemStatePhoto: `&${this.ENTITY_PHOTO_PRIMARY_KEY}, ${this.ENTITY_PHOTO_FOREIGN_KEY}, setId`,
      repairStampPhoto: `&${this.ENTITY_PHOTO_PRIMARY_KEY}, ${this.ENTITY_PHOTO_FOREIGN_KEY}, setId`,
    });
  }

  async initDb(): Promise<PromiseExtended<Dexie>> {
    // Dexie handles the creation and upgrading of stores automatically.
    // Additional initialization logic can go here if needed.
    // Since Dexie uses Promises, you can just return the Dexie database open promise.
    await this.open();
    return this;
  }

  bulkPut<T>(entity: Array<T>, table: Table<T, number>) {
    return from(table.bulkPut(entity)).pipe(catchError(this.handleError('bulkPut')));
  }

  put<T>(entity: T, table: Table<T, number>): Observable<number> {
    return from(table.put(entity)).pipe(catchError(this.handleError('put')));
  }

  hardDelete<T>(key: number | string, table: Table<T, number | string>): Observable<void> {
    return from(table.delete(key)).pipe(catchError(this.handleError('hardDelete')));
  }

  bulkDelete<T>(keys: number[], table: Table<T, number>): Observable<void> {
    return from(table.bulkDelete(keys)).pipe(catchError(this.handleError('bulkDelete')));
  }

  private handleError(methodName: string) {
    return (error: any) => {
      console.error(`Error in PhotoDataDexieService.${methodName}:`, error);
      throw error;
    };
  }
}
