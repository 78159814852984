import { Injectable, OnDestroy } from '@angular/core';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppAction from './../actions/application.actions';
import { catchError, map, Observable, of, Subscription, switchMap, take, tap } from 'rxjs';
import { GenericErrorAction } from '@app/store/models/GenericErrorAction';
import { translate, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/store/reducers';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationEffects implements OnDestroy {
  translocoSub: Subscription;

  constructor(
    private http: HttpClient,
    private readonly store: Store<fromRoot.State>,
    private readonly actions$: Actions,
    private readonly snackBarService: CxSnackbarService,
    private translocoService: TranslocoService
  ) {
    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  genericError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppAction.genericErrorAction),
        tap((action: GenericErrorAction) => {
          const DEFAULT_MSG = translate('errors.we-have-some-issues-with-the-server-please-try-again-later');
          this.snackBarService.error(action.message ? action.message : DEFAULT_MSG);
        })
      ),
    { dispatch: false }
  );

  checkNetworkStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(AppAction.checkNetworkStatusAction),
      switchMap(() => this.pingDiagnosticEndpoint()),
      map((isOnline) => AppAction.setIsOnlineAction({ payload: { isOnline } }))
    )
  );

  pingDiagnosticEndpoint(): Observable<boolean> {
    return this.http.get(environment.diagnosticEndpoint, { responseType: 'text' }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  toggleNetworkStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(AppAction.toggleNetworkStatusAction),
      map(() => {
        // Get current online status from store
        let currentStatus: boolean = false;
        this.store
          .select(fromRoot.getIsOnline)
          .pipe(take(1))
          .subscribe((status) => {
            currentStatus = status;
          });

        // Toggle the status
        const newStatus = !currentStatus;

        return AppAction.setIsOnlineAction({ payload: { isOnline: newStatus } });
      })
    )
  );

  goOffline = createEffect(() =>
    this.actions$.pipe(
      ofType(AppAction.goOfflineAction),
      switchMap(() => of(false)),
      map((isOnline) => AppAction.setIsOnlineAction({ payload: { isOnline } }))
    )
  );
}
