import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, of, Subscription, take } from 'rxjs';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { TranslocoService } from '@ngneat/transloco';
import { HttpService } from '@shared/services/http/http.service';
import { ApplicationFacade } from '@app/store/facade/application.facade';
import { SetPhoto } from '@shared/models/offline.model';

@Injectable({
  providedIn: 'root',
})
export class CloudPhotoService implements OnDestroy {
  PHOTO_UPLOAD_URL_SET = environment.backendUrl + '/photo/upload/set';
  PHOTO_DOWNLOAD_URL_SET = 'photo/download/set';

  translocoSub: Subscription;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private responseHandlerService: ResponseHandlerService,
    private applicationFacade: ApplicationFacade,

    private translocoService: TranslocoService
  ) {
    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  uploadSetPhoto(file: File): Observable<Object> {
    const formData = new FormData();
    formData.append(`photo`, file, file.name);

    return this.http.put(this.PHOTO_UPLOAD_URL_SET, formData);
  }

  downloadPhotoSet(photoPath: string | undefined): Observable<Blob | undefined> {
    if (!photoPath) {
      console.error('Photo path for Set is empty');
      return of(undefined);
    }
    let params = new HttpParams().set('photoPath', photoPath.toString());
    return this.httpService.request(this.PHOTO_DOWNLOAD_URL_SET).params(params).getBlob().pipe(take(1));
  }

  uploadOfflineSetPhoto(offlineSet: SetPhoto): Observable<Object> {
    const formData = new FormData();
    const photoAsFile = new File([offlineSet.photo], `${offlineSet.photoFileName}`, { type: 'image/jpeg' });
    formData.append('photo', photoAsFile, photoAsFile.name);
    formData.append('overwritePhoto', 'true');

    return this.http.put(this.PHOTO_UPLOAD_URL_SET, formData);
  }

  /**
   * Helper methods
   */

  handleUploadPhotoSubscription<T>(observable: Observable<T>, errorMessage: string, onComplete?: () => void): void {
    this.applicationFacade.startLoading();
    observable.pipe(take(1)).subscribe({
      next: () => {
        this.applicationFacade.stopLoading();
        if (onComplete) onComplete();
      },
      error: (err) => {
        this.applicationFacade.stopLoading();
        this.responseHandlerService.handleError(errorMessage);
        console.error(err);
      },
      complete: () => {
        this.applicationFacade.stopLoading();
      },
    });
  }
}
