import { Injectable } from '@angular/core';
import { concat, mergeMap, Observable, of, take, throwError } from 'rxjs';
import {
  BBraunReference,
  InAReference,
  Item,
  MasterDataProduct,
  ProductWrapper,
  ReferenceWrapper,
  Set,
  SetTitle,
} from '@shared/models/project-details.model';
import { HttpService } from '@shared/services/http/http.service';
import { EMDNEntity } from '@shared/models/offline.model';
import { ReasonService } from '@app/modules/projects-overview/core/services/reason.service';
import { CategoryReasonsVM } from '@shared/models/static-data.model';
import { HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { MasterDataProductIdbService } from '@pwa/indexed-db/services/static-data/master-data-product-idb.service';
import { BBraunReferenceIdbService } from '@pwa/indexed-db/services/static-data/b-braun-reference-idb.service';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { InAReferenceIdbService } from '@pwa/indexed-db/services/dynamic-data/ina-reference/in-a-reference-idb.service';
import { ProjectCategoryReasonsIdbService } from '@pwa/indexed-db/services/static-data/project-category-reasons-idb.service';
import { translate } from '@ngneat/transloco';
import { HeaderConfigService } from '@app/modules/project-details/core/services/header-config.service';
import { Page } from '@shared/models/paginaton.model';

@Injectable({
  providedIn: 'root',
})
export class DownloadDataService {
  private OFFLINE_STATIC_DATA_ENDPOINT = 'offline/download/static-data';
  private SET_TITLES_BY_PROJECT_ID_ENDPOINT = 'offline/download/set-titles-by-project-id';
  private SETS_BY_PROJECT_ID_ENDPOINT = 'offline/download/sets-by-project-id';
  private NESTED_ITEMS_BY_PROJECT_ID_ENDPOINT = 'offline/download/nested-items-by-project-id';
  private INA_REFERENCES_ENDPOINT = 'ina-references/project-id';

  constructor(
    readonly masterDataProductIdbService: MasterDataProductIdbService,
    readonly bBraunReferenceIdbService: BBraunReferenceIdbService,
    readonly inaReferenceIdbService: InAReferenceIdbService,
    readonly responseHandlerService: ResponseHandlerService,
    private readonly httpService: HttpService,
    private readonly reasonService: ReasonService,
    private readonly projectCategoryReasonsIdbService: ProjectCategoryReasonsIdbService,
    private headerConfigService: HeaderConfigService
  ) {}

  /**
   * Project data
   */

  getSetTitlesByProjectId(projectId: number | string): Observable<SetTitle[]> {
    return this.httpService
      .request(this.SET_TITLES_BY_PROJECT_ID_ENDPOINT)
      .param('projectId', String(projectId))
      .get()
      .pipe(
        catchError((error) => {
          console.error('Error in getSetTitlesByProjectId()', error);
          return throwError(() => error);
        })
      );
  }

  getSetsByProjectId(projectId: number | string): Observable<Set[]> {
    return this.httpService
      .request(this.SETS_BY_PROJECT_ID_ENDPOINT)
      .param('projectId', String(projectId))
      .get()
      .pipe(
        catchError((error) => {
          console.error('Error in getSetsByProjectId()', error);
          return throwError(() => error);
        })
      );
  }

  getNestedItemsByProjectId(projectId: number | string, pageIndex: number = 0, pageSize: number = 1000): Observable<Page<Item>> {
    let params = new HttpParams().set('projectId', String(projectId)).set('pageIndex', pageIndex).set('pageSize', pageSize);
    return this.httpService.request(this.NESTED_ITEMS_BY_PROJECT_ID_ENDPOINT).params(params).get();
  }

  downloadAndPersistProjectInAReferences(projectId: number | string): Observable<any> {
    return this.getInAReferencesByProject(projectId).pipe(
      mergeMap((inAReferences) => this.inaReferenceIdbService.bulkPostInAReference(inAReferences)),
      catchError((error) => {
        console.error(`Error downloadAndPersistInAReferences for project id ${projectId}`, error);
        return of([]);
      })
    );
  }

  getInAReferencesByProject(projectId: number | string): Observable<InAReference[]> {
    return this.httpService.request(this.INA_REFERENCES_ENDPOINT + `/${projectId}`).get();
  }

  /**
   * Static data
   */
  getGeneralStaticData(): Observable<any> {
    return this.httpService.request(this.OFFLINE_STATIC_DATA_ENDPOINT + '/different-domains').get();
  }

  downloadAndPersistAllMasterDataProducts(): void {
    this.headerConfigService.shouldDisplaySpinner.next(true);
    this.headerConfigService.spinnerTooltip.next('header.tooltip-downloading-offline-data-in-progress');
    this.responseHandlerService.handleInfo(translate('snacks.download-in-progress-preparing-offline-data'));
    const totalPages = 10;
    const pageSize = 50000;
    let observables: Observable<any>[] = [];

    for (let page = 0; page < totalPages; page++) {
      observables.push(this.downloadAndPersistMasterDataProduct(page, pageSize));
    }

    concat(...observables).subscribe({
      next: () => {},
      error: (error) => {
        console.error('Error in download and persist process: ', error);
        this.headerConfigService.shouldDisplaySpinner.next(false);
      },
      complete: () => {
        this.headerConfigService.shouldDisplaySpinner.next(false);
        this.responseHandlerService.handleSuccess(translate('snacks.download-complete-offline-data-is-now-ready-for-use'));
        console.log('All pages processed.');
      },
    });
  }

  downloadAndPersistMasterDataProduct(page: number, size: number) {
    return this.getStaticDataMasterDataProducts(page, size).pipe(
      mergeMap((masterData) => {
        if (masterData.length > 0) {
          return this.masterDataProductIdbService.postMasterDataProductOffline(masterData).pipe(
            catchError((error) => {
              console.error(`Error persisting master data for page ${page}`, error);
              return of(`Error persisting data for page ${page}`);
            })
          );
        } else {
          console.warn(`No data returned for page ${page}`);
          return of(`No data for page ${page}`);
        }
      })
    );
  }

  downloadAndPersistAllReferences(): void {
    const totalPages = 4;
    const pageSize = 50000;
    let observables: Observable<any>[] = [];

    for (let page = 0; page < totalPages; page++) {
      observables.push(this.downloadAndPersistBBraunReferencesDataProduct(page, pageSize));
    }

    concat(...observables).subscribe({
      next: () => {},
      error: (error) => {
        console.error('BBraun Reference Error in download and persist process: ', error);
      },
      complete: () => {
        console.log('BBraun ReferenceAll pages processed.');
      },
    });
  }

  downloadAndPersistBBraunReferencesDataProduct(page: number, size: number) {
    return this.getStaticDataBBraunReference(page, size).pipe(
      mergeMap((masterData) => {
        if (masterData.length > 0) {
          return this.bBraunReferenceIdbService.postBBraunReferenceDataOffline(masterData).pipe(
            catchError((error) => {
              console.error(`Error persisting BBraun reference for page ${page}`, error);
              return of(`Error persisting  BBraun reference for page ${page}`);
            })
          );
        } else {
          console.warn(`No BBraun reference returned for page ${page}`);
          return of(`No BBraun reference for page ${page}`);
        }
      })
    );
  }

  getStaticDataMasterDataProducts(page: number, size: number): Observable<MasterDataProduct[]> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.httpService
      .request(`${this.OFFLINE_STATIC_DATA_ENDPOINT}/master-data-products`)
      .params(params)
      .get()
      .pipe(
        take(1),
        map((productWrapper: ProductWrapper) => productWrapper.masterDataProducts),
        catchError((error) => {
          console.error(`Error fetching master data products for page ${page}`, error);
          return of([]); // Return an empty array on error to continue the process
        })
      );
  }

  getStaticDataBBraunReference(page: number, size: number): Observable<BBraunReference[]> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.httpService
      .request(`${this.OFFLINE_STATIC_DATA_ENDPOINT}/bbraun-references`)
      .params(params)
      .get()
      .pipe(
        take(1),
        map((referenceWrapper: ReferenceWrapper) => referenceWrapper.references),
        catchError((error) => {
          console.error(`Error fetching reference data for page ${page}`, error);
          return of([]); // Return an empty array on error to continue the process
        })
      );
  }

  getStaticDataEmdnEntries(): Observable<EMDNEntity[]> {
    return this.httpService.request(`${this.OFFLINE_STATIC_DATA_ENDPOINT}/emdn`).get() as Observable<EMDNEntity[]>;
  }

  downloadAndPersistProjectReasons(projectId: number | string): Observable<any> {
    return this.reasonService.getReasonsByProjectId(projectId).pipe(
      mergeMap((projectReasonDTO) => this.projectCategoryReasonsIdbService.postProjectReasons(projectReasonDTO)),
      catchError((error) => {
        console.error(`Error downloadAndPersistInAReferences for project id ${projectId}`, error);
        return of([]);
      })
    );
  }

  getDefaultProjectCategoryReasonsVM(): Observable<CategoryReasonsVM[]> {
    return this.reasonService.getAllReasons();
  }
}
