<ng-container *transloco="let t; read: 'unauthorised'">
  <cx-header>
    <cx-header-title>{{ t('header-title') }}</cx-header-title>
    <cx-header-quickbar>
      <button mat-icon-button class="cx-header-white-icon" [routerLink]="'/'">
        <mat-icon class="white-medium-icon">autorenew</mat-icon>
      </button>
      <ng-container *transloco="let altT; read: 'header'">
        <button
          mat-icon-button
          class="cx-header-white-icon network-status-icon-wrapper"
          (click)="toggleOnlineStatus()"
          [matTooltip]="isOnline ? altT('online-icon') : altT('offline-icon')"
          [routerLink]="'/'"
        >
          @if (isOnline) {
            <mat-icon class="white-medium-icon online-icon">wifi_on</mat-icon>
          } @else {
            <mat-icon class="white-medium-icon offline-icon">wifi_off</mat-icon>
          }
        </button>
      </ng-container>
    </cx-header-quickbar>
  </cx-header>
  <cx-message>
    <cx-message-title>{{ t('message-title') }}</cx-message-title>
    <cx-message-image [imageSrc]="'assets/images/unauthorised.svg'" />
    <cx-message-text>
      <div class="text-center">{{ t('message-text') }}</div>
    </cx-message-text>
  </cx-message>
</ng-container>
