import { Injectable } from '@angular/core';
import { ProjectDetailsService } from '@app/modules/project-details/core/services/project-details.service';
import { Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { Speciality } from '@shared/models/project-details.model';

@Injectable({
  providedIn: 'root',
})
export class SpecialityService {
  constructor(private projectDetailsService: ProjectDetailsService) {}

  getAllByProjectId(projectId: number | string): Observable<Speciality[]> {
    return this.projectDetailsService
      .getSpecialitiesByProjectId(projectId)
      .pipe(map((sets) => sets.sort((a, b) => a.name.localeCompare(b.name))));
  }

  getById(id: number | string): Observable<Speciality> {
    return this.projectDetailsService.getSpecialityById(id).pipe(take(1));
  }
}
